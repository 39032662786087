/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import * as db from './db.js';

import * as anagrafica from './anagrafica.js';

export function ariete(id_farm_sell, id_farm){

  var s=storage.get();
  var ram;

  if (s.arieti) {
    // debugger
    if (s.arieti[id_farm]) {
      // console.log(s.arieti[id_farm].data);
      jQuery.each(s.arieti[id_farm].data,function(k,v){
        if (v.id_farm_sell==id_farm_sell) {
          ram=v;
        }
      });
    }
  }

  console.log(ram);


  var html='';

  html+='<div class="card">';
    html+='<div class="card-header">Compatibilità</div>';
    html+='<div class="card-content card-content-padding text-align-center">';
      html+='<div class="gauge demo-gauge"></div>';
    html+='</div>';
  html+='</div>';

  html+='<div class="list form-store-data" id="my-form">';
    html+='<ul>';
      // html+='<li>';
      //   html+='<div class="item-content item-input inline-label">';
      //     html+='<div class="item-inner">';
      //       html+='<div class="item-title item-label">Razza</div>';
      //       html+='<div class="item-input-wrap">';
      //         html+='<select name="razza" disabled>';
      //           html+='<option value="Alpagota" selected>Alpagota</option>';
      //           html+='<option value="Lamon" selected>Lamon</option>';
      //         html+='</select>';
      //       html+='</div>';
      //     html+='</div>';
      //   html+='</div>';
      // html+='</li>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Azienda</div>';
            html+='<div class="item-input-wrap">';
              html+='<input type="text" name="name" placeholder="Your name" value="'+ram.seller_name+'" disabled>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      // html+='<li>';
      //   html+='<div class="item-content item-input inline-label">';
      //     html+='<div class="item-inner">';
      //       html+='<div class="item-title item-label">Codice Ariete</div>';
      //       html+='<div class="item-input-wrap">';
      //         html+='<input type="text" name="name" placeholder="Your name" value="'+ram.animal_code+'" disabled>';
      //       html+='</div>';
      //     html+='</div>';
      //   html+='</div>';
      // html+='</li>';

      // html+='<li>';
      //   html+='<div class="item-content item-input inline-label">';
      //     html+='<div class="item-inner">';
      //       html+='<div class="item-title item-label">BCS</div>';
      //       html+='<div class="item-input-wrap">';
      //         html+='<div id="slider" class="range-slider range-slider-init" data-min="0" data-max="5" data-label="true" data-step="0.5" data-value="3" data-scale="true" data-scale-steps="5" data-scale-sub-steps="2"></div>';
      //       html+='</div>';
      //     html+='</div>';
      //   html+='</div>';
      // html+='</li>';

    html+='</ul>';
  html+='</div>';

  // html+='<button id="interesse" class="button button-large button-outline"><i class="icon f7-icons color-red">heart</i><span>Mi interessa</span> </button>';
  html+='<div id="arieti_list"></div>';

  html+='<div id="only_interessa" style="display:none;" class="list form-store-data" id="my-form">';
    html+='<ul>';
    html+='<li>';
      html+='<div class="item-content item-input inline-label">';
        html+='<div class="item-inner">';
        html+='<div class="item-title item-label">Dal</div>';
          html+='<div class="item-input-wrap">';

            var today = anagrafica.getTodayDate();

            html+='<input type="text" placeholder="Data verbale" readonly="readonly" id="da" value="'+today+'" />';
          html+='</div>';
        html+='</div>';
      html+='</div>';
    html+='</li>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
          html+='<div class="item-title item-label">Al</div>';
            html+='<div class="item-input-wrap">';
              html+='<input type="text" placeholder="Data verbale" readonly="readonly" id="a" value="'+today+'" />';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
    html+='</ul>';
    html+='<div style="margin-top:35px;" class="blocxk">';
      html+='<div class="row">';
        html+='<button id="invia" class="button button-fill color-green">Invia</button>';
      html+='</div>';
    html+='</div>';
  html+='</div>';


  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var urladdr = global.base_call+"get_rams_for_farm";
  jQuery.ajax({
    type: "GET",
    url: urladdr,
    data:{
      access_token:s.user.access_token,
      id_farm:id_farm_sell,
    },
    dataType: "json",
    success: function(arieti){
      console.log(arieti);
      var html='';
      jQuery.each(arieti.data,function(k,v){
        var image=`<div class="card-header align-items-flex-end"><img height="100%" width="100%" src="https://sheepallchain.aedit.it/sites/default/files/images/${v.image}.png"></div>`;
        var scrapie=`- <span class="badge color-${v.scrapie_color}">${v.scrapie}`;
        html+=`
        <div class="card demo-card-header-pic">
          ${v.image?image:''}
          <div class="card-content card-content-padding">
            <p class="date">${v.breed} - <b>${v.name?v.name:v.code}</b> ${v.scrapie?scrapie:''}</span></p>
            <p>Data di nascita: ${v.date_birth}</p>
          </div>
        </div>
        `;

      });
      jQuery('#arieti_list').html(html);
    }
  });


  var phone='Chiamata non disponibile';
  var phoneHref='';
  var phoneColor='gray';
  if (ram.phone!=null) {
    phone="Chiamami";
    phoneHref='href="tel:'+ram.phone+'"';
    phoneColor='green';
  }

  var mail='Email non disponibile';
  var mailHref='';
  var mailColor='gray';
  if (ram.email!=null) {
    mail="Scrivimi";
    mailHref='href="mailto:'+ram.email+'"';
    mailColor='blue';
  }


  var buttons='';
  buttons+='<div class="col-xs-6" style="width: 100%;">';
    buttons +='<a '+phoneHref+' style="margin-right:5px; height:30px;" id="phone" class="item-link link external item-content button button-fill color-'+phoneColor+'">'+phone+'</a>';
  buttons+='</div>';
  buttons+='<div class="col-xs-6" style="width: 100%;">';
    buttons +='<a '+mailHref+' style="margin-right:5px; height:30px;" id="email" class="item-link link external item-content button button-fill color-'+mailColor+'">'+mail+'</a>';
  buttons+='</div>';

  jQuery('#footer').html(buttons);


  jQuery("#invia").click(function() {
    history.back();
  });

  var calendarDefaulta = global.app.calendar.create({
    inputEl: '#da',
    closeOnSelect:true
  });


  var calendarDefaultb = global.app.calendar.create({
    inputEl: '#a',
    closeOnSelect:true
  });

  jQuery("#interesse").click(function() {
    if (jQuery('#interesse i').text()=='heart') {
      jQuery('#interesse i').text('heart_fill');
      jQuery('#only_interessa').show();
    }
    else {
      jQuery('#interesse i').text('heart');
      jQuery('#only_interessa').hide();
    }
  });


  var compatibilityText="compatibilità";
  var percentuale=parseFloat(ram.score)*10;
  var scoreColor='#2196f3';

  if (percentuale<=100 && percentuale>75) {
    compatibilityText="Ottima compatibilità";
    scoreColor='#43A047';
  }
  else if (percentuale<=75 && percentuale>50) {
    compatibilityText="Alta compatibilità";
    scoreColor='#FDD835';
  }
  else if (percentuale<=50 && percentuale>25) {
    compatibilityText="Media compatibilità";
    scoreColor='#FB8C00';
  }
  else if (percentuale<=25 && percentuale>0) {
    compatibilityText="Bassa compatibilità";
    scoreColor='#E53935';
  }

  var demoGauge = global.app.gauge.create({
    el: '.demo-gauge',
    type: 'circle',
    value: percentuale/100,
    size: 250,
    borderColor: scoreColor,
    borderWidth: 10,
    valueText: percentuale+'%',
    valueFontSize: 41,
    valueTextColor: scoreColor,
    labelText: compatibilityText,
  });

  var range = global.app.range.create({
    el: '#slider',
    on: {
      change: function () {
        console.log('Range Slider value changed');
      }
    }
  });
}

export function arietiRichieste(id_farm){
  var html='';
  html+="<div class='block-title block-title-medium central'>Richieste arrivate</div>";

  html+='<div class="data-table data-table-init card">';
    html+='<div class="card-content">';
      html+='<table>';
        html+='<thead>';
          html+='<tr>';
            html+='<th class="label-cell">Azienda</th>';
            html+='<th class="numeric-cell">Inizio</th>';
            html+='<th class="numeric-cell">Fine</th>';
            html+='<th class="numeric-cell">Accetta</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody>';

          html+='<tr>';
            html+='<td class="label-cell">Azienda X</td>';
            html+='<td class="numeric-cell">1 Gennaio 2020</td>';
            html+='<td class="numeric-cell">31 Marzo 2020</td>';
            html+='<td class="numeric-cell"><button class="button button-fill">Accetta</button></td>';
          html+='</tr>';

          html+='<tr>';
            html+='<td class="label-cell">Azienda Y</td>';
            html+='<td class="numeric-cell">1 Aprile 2020</td>';
            html+='<td class="numeric-cell">30 Giugno 2020</td>';
            html+='<td class="numeric-cell"><button class="button button-fill">Accetta</button></td>';
          html+='</tr>';

        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';

  html+="<div class='block-title block-title-medium central'>Richieste inviate</div>";

  html+='<div class="data-table data-table-init card">';
    html+='<div class="card-content">';
      html+='<table>';
        html+='<thead>';
          html+='<tr>';
            html+='<th class="label-cell">Azienda</th>';
            html+='<th class="numeric-cell">Inizio</th>';
            html+='<th class="numeric-cell">Fine</th>';
            html+='<th class="numeric-cell">Stato</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody>';

          html+='<tr>';
            html+='<td class="label-cell">Azienda X</td>';
            html+='<td class="numeric-cell">1 Gennaio 2020</td>';
            html+='<td class="numeric-cell">31 Marzo 2020</td>';
            html+='<td class=" "><span class="badge color-red">rifiutato</span></td>';
          html+='</tr>';

          html+='<tr>';
            html+='<td class="label-cell">Azienda Y</td>';
            html+='<td class="numeric-cell">1 Aprile 2020</td>';
            html+='<td class="numeric-cell">30 Giugno 2020</td>';
                      html+='<td class="numeric-cell"><span class="badge color-green">accettato</span></td>';
                      html+='</tr>';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);


}

function drawArieti(id_farm, data){
  var html='';
  html+="<div class='block-title block-title-medium central' id='nome_azienda'></div>";

  if (data.data.length>0) {
    html+='<div class="list">';
      html+='<ul>';

        jQuery.each(data.data,function(k,v){
          console.log(v);
          var scoreColor='grey';

          if (v.score<=10 && v.score >7.5) {
            scoreColor='green';
          }
          else if (v.score<=7.5 && v.score >5) {
            scoreColor='yellow';
          }
          else if (v.score<=5 && v.score >2.5) {
            scoreColor='orange';
          }
          else if (v.score<=2.5) {
            scoreColor='red';
          }


          html+='<li>';
            html+='<a href="#farm/'+id_farm+'/arieti/'+v.id_farm_sell+'" class="item-link link external item-content">';
              html+='<div class="item-media"><span class="badge badge-sheep color-'+scoreColor+'">'+v.score+'</span></div>';
              html+='<div class="item-inner">';
                html+='<div class="item-title" style="white-space:unset;">';
                  html+='<div class="item-header">Proprietario ariete</div>';
                  html+=v.seller_name;
                  // html+='<div class="item-footer">'+v.animal_code+'</div>';
                html+='</div>';
                html+='<div class="item-after">Apri scheda</div>';
              html+='</div>';
            html+='</a>';
          html+='</li>';
        });

      html+='</ul>';
    html+='</div>';
  }
  else {
    html+='<h3 style="text-align: center;font-size: 30px;">Non ci sono arieti per questa azienda.</h3>';

  }

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

}

export function allArieti(id_farm){


  console.log("Arieti");
  db.getArieti( id_farm, function(data){
    drawArieti(id_farm,data);
  });


  // var notificationFull = global.app.notification.create({
  //   icon: '<img src="https://www.netclipart.com/pp/m/35-359717_outline-sheep-svg-clip-arts-600-x-540.png" width="30">',
  //   title: 'SheepAllChain',
  //   titleRightText: 'now',
  //   subtitle: 'Versione di DEMO',
  //   text: 'Questa funzione è ancora in via di sviluppo.',
  //   closeOnClick: true,
  //   closeTimeout: 5000,
  // }).open();

  getGregge(id_farm);
}


//Disegna la home con agarafica e parti
function drawAnag(id_farm, data){
  console.log("DIEGOANA",id_farm);
  // console.log(data);

  var s=storage.get();

  var herd={
    m_adult:0,
    f_adult:0,
    m_baby:0,
    f_baby:0
  };


  var html='';
  // console.log(data);
  if (data.animals) {

    jQuery('.icons_animals').show();

    jQuery.each(data.animals.data,function(k,v){
      var age=anagrafica.getAge(v.date_birth);
      var years=age.years;
      var months=age.months;


      var status="";
      if (years>0 || (years==0 && months>6)) {
        status="adult";
        if (v.sex=='M') {
          herd.m_adult ++;
        }
        else if (v.sex=='F') {
          herd.f_adult ++;
        }
      }
      else {
        status="baby";
        if (v.sex=='M') {
          herd.m_baby ++;
        }
        else if (v.sex=='F') {
          herd.f_baby ++;
        }
      }

    });

    var all_animals=jQuery.merge( jQuery.merge( [], data.animals.data ), data.recently_deleted.data );

    // console.log("DIEGOPRE", all_animals)
    calcolaParti(all_animals ,herd.f_adult);
  }


  jQuery.each(herd,function(k,v){
    jQuery('#'+k).html(v);
  });

  var welcome_name=s.user.username;
  try {
    if (data.farm.data.length>0) {
      welcome_name=data.farm.data[0].farm_name;
    }
  }
  catch (e) {
    console.log(e);
  }

  jQuery('#nome_azienda').html(welcome_name);
}

export function fillFarms(data){
  var html='';
  jQuery.each(data.data,function(k,v){
    html+='<tr onclick="location.hash=\'farm/'+v.id_farm+'\'">';
      html+='<td>'+v.farm_name+'</td>';
      html+='<td><button class="button"><i class="f7-icons">play_circle_fill</i></button></td>';
    html+='</tr>';
    html+='<option value="'+v.id_farm+'">'+v.farm_name+'</option>';
  });

  jQuery('#farm_list').html(html);

  jQuery('.farm_select').show();
}


export function getFarms(){
  db.getAllFarms( function(data){
    fillFarms(data);
  });
}


//Home per la sincronizzazione
export function getGregge(id_farm){
  // console.log(id_farm);
  db.sync(id_farm,function(data){
    drawAnag(id_farm, data);

    jQuery('#farm_name').html(data.farm.data[0].name);
  });
}

function calcolaParti(animals,pecore){

  var num_mesi=[36, 24, 12];
  var partiXpecoraAttesi=[4.5, 3 ,1.5];
  // var num_mesi=[24];

  jQuery.each(num_mesi,function(i,d){


    //Elenco delle nascite e dei parti.
    //Le nascite possono essere segnate in due modi, come evento o come nuovo agnello nato
    var parti=[];
    var nascite=[];

    var checkDate=addToMonth(null,-d);
    checkDate=new Date(checkDate).getFullYear()+"-"+anagrafica.addZero(new Date(checkDate).getMonth()+1)+"-"+anagrafica.addZero(new Date(checkDate).getDate());

    console.log("DiegoMesi",d, checkDate);

    //Il "metodoParti" considera gli agnelli degli eventi (anche se non segnati come nuovi agnelli in anagrafica)
    var method_parti=true;
    if(method_parti){

      //metodo che crea i parti dalle segnalazioni di event delivery
      jQuery.each(animals,function(k,v){
        if(v.json){
          // console.log(v.json);
          var json=v.json;
          if (typeof v.json=='string') {
            json=JSON.parse(v.json).events;
          }
          // console.log("JSON-----");
          // console.log(json);
          // console.log("---------");
          jQuery.each(json,function(ke,event){
            if(event.type=='delivery'){

              //Scegli solo le date corrette
              if(checkDate<event.date){
                var aParti={'source': 'events', 'guid_mother': v.guid, 'date_birth': event.date};
                parti.push(aParti);

                var num_tot=event.number_m+event.number_f;
                for(var i=0; i<num_tot; i++){
                  var cod_agnello='xxxxx';
                  var aNascite={"source": "events",  "cod_agnello": cod_agnello, "date_birth": event.date, "guid_mother": v.guid};
                  nascite.push(aNascite);
                }
              }

            }
          });

        }
      });
    }


      //Scorre tutti gli animali per caricare degli agnelli messi manualmente
      jQuery.each(animals,function(k,v){
        //Vann filtrati solo gli agnelli nati in azienda da meno di x mesi
        if (v.type_in=='Nascita' && v.date_birth>checkDate) {
          // console.log(d+"mesi "+v.date_birth);
          var aParti={'source': 'anag', 'guid_mother': v.guid_mother, 'date_birth': v.date_birth};

          //se sono stati messi già prima non li considera
          var found = false;
          for(var i = 0; i < parti.length; i++) {
            if (parti[i][v.guid_mother] == v.date_birth) {
              found = true;
              break;
            }
          }
          if (!found) {
            parti.push(aParti);
          }

          if(!method_parti){
            var aNascite={"source": "anag", "cod_agnello": v.guid, "date_birth": v.date_birth, "guid_mother": v.guid_mother};
            nascite.push(aNascite);
          }
        }
      });


    //Copntando abbiamo anche dei duplicati
    // console.log("DiegoParti", parti);
    var parti_unici={};
    //TODO Attenzione, dobbiamo verificare che non ci siano dei doppi ingressi (sia da events che da anag), oppure dei parti troppo ravvicinati
    jQuery.each(parti, function(k,p){
      var guid_mother=p.guid_mother;
      if(guid_mother==0 || guid_mother==null){
        guid_mother="mm"+k;
      }

      if(!parti_unici[guid_mother]){
        parti_unici[guid_mother]=[];
      }
      parti_unici[guid_mother].push(p.date_birth);
    });

    console.log("Diego",parti_unici);
    // debugger
    var partiUnici=0;
    jQuery.each(parti_unici, function(k,p){
      var old_date='2000-01-01';
      var pp=0;
      var dates=p.sort();
      jQuery.each(dates, function(k2,date){
        // console.log("confronta "+date+" con "+old_date);
        // console.log(diffDates(old_date,date));

        //Verifica che le date siano distanti almeno 3 mesi - 90GIORNI
        if(diffDates(old_date,date)>90){
          partiUnici=partiUnici+1;
        }
        old_date=date;
      });
    });

    console.log("Diego",partiUnici, pecore);

    var partiXpecora=partiUnici/pecore;

    var percentuale=partiXpecora/partiXpecoraAttesi[i];
    if(pecore===0){
      partiXpecora=0;
      percentuale=0;
    }

    // console.log(percentuale);

    var color='#2196f3';
    if(percentuale<0.33){
      color='#E53935'; //rosso
    }
    else if(percentuale<0.66){
      color='#FF9800';   // arancio
    }
    else{
      color="#2E7D32"; //verde
    }

    var demoGauge = global.app.gauge.create({
      el: '.mesi-'+d,
      type: 'semicircle',
      value: percentuale,
      size: 250,
      borderColor: color,
      borderWidth: 10,
      valueText: (percentuale*100).toFixed(0)+'%',
      valueFontSize: 60,
      valueTextColor: color,
      labelText: 'su '+d+' mesi',
      labelFontSize:35
    });

    jQuery('.text-parti-mesi-'+d).html(partiUnici+' parti');

    // console.log(parti);
    // console.log(nascite);
    // console.log(d+" mesi - "+partiXpecora);
    // console.log("-------------------------------");

  });

}

function diffDates(oldDate,newDate){
  var date1 = new Date(oldDate);
  var date2 = new Date(newDate);
  var diffTime = Math.abs(date2 - date1);
  var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}


function addToMonth( date, months ) {
  var d = new Date( date || new Date() );
  d.setMonth( d.getMonth() + (months || 0), d.getDate());
  return d;
}

export function arietiHomeCard(){
  var html='';
  html+='<div id="arieti" class="card">';
    html+='<div class="card-header">'+t('rams_choice')+'<img src="icons/m_adult.svg" width="40px"/></div>';
    html+='<div class="card-content card-content-padding">';

      html+='<span class="central">'+t('ram_message')+'</span>';

      html+='<div class="list links-list" style="margin-top: 0;">';
        html+='<ul>';
          html+='<li>';
            html+='<a><span style="text-align: end;">'+t('go_to_choice')+'</span></a>';
          html+='</li>';
        html+='</ul>';
      html+='</div>';
    html+='</div>';

  html+='</div>';

  return html;
}
