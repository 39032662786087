/*jshint esversion: 6 */
import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';
import photo from '../../core/photo.js';

import * as arieti from './arieti.js';

import * as db from './db.js';


// Prepara il form quando voglio inserire un nuovo animale
export function anagrafe_new(id_farm,cod_animale,cod_bdn){
  var html='';
  if (!cod_animale) {
    cod_animale='';
  }
  if (!cod_bdn) {
    cod_bdn='';
  }

  html+='<div class="list form-store-data" id="anagrafe-new">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivazione</div>';
            html+='<div class="item-input-wrap">';
              html+='<select id="motivazione">';
                html+='<option value="Nascita" selected>Nascita</option>';
                html+='<option value="Acquisto">Acquisto</option>';
              html+='</select>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      html+='<li>';
        html+='<div style="display:none" id="codice_azienda_origine_div" class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Azienda origine</div>';
            html+='<div class="item-input-wrap">';
              html+='<input id="codice_azienda_origine" type="text" name="name" placeholder="inserire il codice dell\'azienda di origine">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Cod. animale</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_azienda" type="text" name="name" placeholder="Cod. animale" value="'+cod_animale+'" required validate>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Codice BDN</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_bdn" type="text" name="name" value="'+cod_bdn+'" placeholder="Codice BDN">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Scrapie</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="scrapie">';
                  html+=` <option value=""></option>
                          <option value="ARR/ARR">ARR/ARR</option>
                          <option value="ARR/ARH">ARR/ARH</option>
                          <option value="ARR/AHQ">ARR/AHQ</option>
                          <option value="ARQ/ARR">ARQ/ARR</option>
                          <option value="ARQ/ARQ">ARQ/ARQ</option>
                          <option value="ARQ/AHQ">ARQ/AHQ</option>
                          <option value="AHQ/AHQ">AHQ/AHQ</option>
                          <option value="ARQ/ARH">ARQ/ARH</option>
                          <option value="ARH/ARH">ARH/ARH</option>
                          <option value="AHQ/ARH">AHQ/ARH</option>
                          <option value="VRQ/VRQ">VRQ/VRQ</option>
                          <option value="VRQ/ARQ">VRQ/ARQ</option>
                          <option value="VRQ/ARH">VRQ/ARH</option>
                          <option value="VRQ/AHQ">VRQ/AHQ</option>
                          <option value="VRQ/ARR">VRQ/ARR</option>
                  `;
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Sesso</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="sesso">';
                  html+='<option value="M">Maschio</option>';
                  html+='<option value="F" selected>Femmina</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Razza</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="razza"></select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Padre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="padre"></select>';
                html+='<input style="display:none;" id="disabled_padre" type="text" name="name" placeholder="Inserisci padre">';
              html+='</div>';

              html+='<i id="padre_change" class="f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Madre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="madre"></select>';
                html+='<input style="display:none;" id="disabled_madre" type="text" name="name" placeholder="Inserisci madre">';

              html+='</div>';

              html+='<i id="madre_change" class="f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data di nascita</div>';
              html+='<div class="item-input-wrap">';

                var today = getTodayDate();

                html+='<input id="data_di_nascita" type="text" placeholder="Data verbale" readonly="readonly" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id="ingresso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data ingresso</div>';
              html+='<div class="item-input-wrap">';

                today = getTodayDate();

                html+='<input id="data_in" type="text" placeholder="Data verbale" readonly="readonly" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Note</div>';
            html+='<div class="item-input-wrap">';
              html+='<textarea id="note" placeholder="Le tue note"></textarea>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


    html+='</ul>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery("#padre_change,#madre_change").click(function() {
    var gen=jQuery(this).attr('id').split('_')[0];

    jQuery('#'+gen).attr('id','tmp_disabled_'+gen);
    jQuery('#disabled_'+gen).attr('id',gen);
    jQuery('#tmp_disabled_'+gen).attr('id','disabled_'+gen);

    jQuery('#disabled_'+gen).hide();
    jQuery('#'+gen).show();

    if (jQuery(this).html()=='pencil_circle') {
      jQuery(this).html('pencil_circle_fill');
    }
    else {
      jQuery(this).html('pencil_circle');
    }
  });

  var s=storage.get();
  var options='';
  console.log("bb"+s.res);
  jQuery.each(s.res.breeds.data,function(k,v){
    options+='<option value="'+v.cod_breed+'">'+v.breed+'</option>';
  });
  jQuery('#razza').html(options);

  //in fase di inseriemnto i valori sono nulli
  var date_birth=null;
  var guid_animal=null;
  db.getParents(id_farm, date_birth,guid_animal, function(data){
    drawParents(data, id_farm);
  });

  //Se cambia la data ricalcola i possibili genitori
  jQuery('#data_di_nascita').on("change", function(){
    date_birth=jQuery(this).val();
    db.getParents(id_farm, date_birth,guid_animal, function(data){
      drawParents(data, id_farm);
    });
  });


  var button ='<button id="salva" class="button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#motivazione").change(function() {
    if (jQuery(this).val()=='Nascita') {
      jQuery('#ingresso').hide();
      jQuery('#codice_azienda_origine_div').hide();
    }
    else {
      jQuery('#data_di_nascita').val('');
      jQuery('#ingresso').show();
      jQuery('#codice_azienda_origine_div').show();
    }
  }).change();

  jQuery("#salva").click(function() {
    console.log("ccc");
    jQuery('#anagrafe-new').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var animal={
        guid: getGuid(),
        id_farm:id_farm,
        code:jQuery('#codice_azienda').val(),
        code_bdn:jQuery('#codice_bdn').val(),
        sex:jQuery('#sesso').val(),
        scrapie:jQuery('#scrapie').val(),
        breed:jQuery('#razza').val(),
        date_birth:jQuery('#data_di_nascita').val(),
        date_in:jQuery('#data_in').val(),
        type_in:jQuery('#motivazione').val(),
        guid_mother:jQuery('#madre').val(),
        guid_father:jQuery('#padre').val(),
        note:jQuery('#note').val(),
        farm_in:jQuery('#codice_azienda_origine').val()
      };

      db.insertAnimal(animal, function(all_animals, animal_inserted){
        console.log(animal_inserted.guid);
        location.hash='farm/'+id_farm+'/anagrafe/advanced';
        location.hash='farm/'+id_farm+'/anagrafe/advanced/view/'+animal_inserted.guid;
      });

    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });

  var calendarBirth = global.app.calendar.create({
    inputEl: '#data_di_nascita',
    closeOnSelect:true,
    dateFormat: 'yyyy-mm-dd'
  });
  var calendarIn= global.app.calendar.create({
    inputEl: '#data_in',
    closeOnSelect:true,
    dateFormat: 'yyyy-mm-dd'
  });
}

export function anagrafe_remove(guid,type_out, id_farm){
  var html='';

  html+='<div class="list form-store-data" id="my-form">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Cod. animale</div>';
            html+='<div class="item-input-wrap">';
              html+='<input type="text" id="codice_azienda" disabled>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Motivazione</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="type_out">';
                  html+='<option value="Macellazione">Macellazione</option>';
                  html+='<option value="Morte">Morte</option>';
                  html+='<option value="Vendita" selected>Vendita</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li id=li_macello>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Macello</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="macello" type="text" name="name" placeholder="inserire il macello">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id=li_peso_carcassa>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Peso carcassa</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="peso_carcassa" type="text" name="name" placeholder="inserire il peso in KG">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id=li_motivo_morte>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Motivo morte</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="motivo_morte" type="text" name="name" placeholder="Motivo della morte">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li id=li_azienda_destinazione>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Azienda destinazione</div>';
              html+='<div class="item-input-wrap">';
                html+='<input id="codice_azienda_destinazione" type="text" name="name" placeholder="inserire il codice dell\'azienda di destinazione">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data</div>';
              html+='<div class="item-input-wrap">';

                var today = getTodayDate();

                html+='<input type="text" placeholder="Data verbale" readonly="readonly" id="data_out" value="'+today+'" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
    html+='</ul>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery("#type_out").change(function() {
     if (jQuery('#type_out').val()=='Macellazione') {
       jQuery('#li_macello').show();
       jQuery('#li_peso_carcassa').show();

       jQuery('#li_motivo_morte').hide();

       jQuery('#li_azienda_destinazione').hide();
     }
     else if(jQuery('#type_out').val()=='Morte') {
       jQuery('#li_motivo_morte').show();

       jQuery('#li_azienda_destinazione').hide();

       jQuery('#li_macello').hide();
       jQuery('#li_peso_carcassa').hide();
     }
     else {
       jQuery('#li_azienda_destinazione').show();

       jQuery('#li_motivo_morte').hide();

       jQuery('#li_macello').hide();
       jQuery('#li_peso_carcassa').hide();
     }
  });

  jQuery('#type_out').val(type_out).change();




  var button='<button id="delete" class="button button-fill color-red">Salva</button>';
  jQuery('#footer').html(button);

  getAnimal(guid,id_farm);


  jQuery("#delete").click(function() {
    var animal = db.get_animal_from_guid(guid, id_farm);
    animal.date_out=jQuery('#data_out').val();
    animal.type_out=jQuery('#type_out').val();
    animal.farm_out=jQuery('#codice_azienda_destinazione').val();
    animal.macello=jQuery('#macello').val();
    animal.peso_carcassa=jQuery('#peso_carcassa').val();
    animal.motivo_morte=jQuery('#motivo_morte').val();
    db.save_animal(animal);

    db.updateAnimal(animal, function(){
      history.back();
    });
  });

  var calendarOut = global.app.calendar.create({
    inputEl: '#data_out',
    closeOnSelect:true
  });
}

export function anagrafe_edit(guid,id_farm){
  var html='';

  html+='<div class="list form-store-data" id="anagrafe-edit">';
    html+='<ul>';
      html+='<li>';
        html+='<div class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivazione</div>';
            html+='<div class="item-input-wrap">';
              html+='<select class="disabled-if-rimosso" id="motivazione">';
                html+='<option value="Nascita" selected>Nascita</option>';
                html+='<option value="Acquisto">Acquisto</option>';
              html+='</select>';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      html+='<li>';
        html+='<div  id="codice_azienda_origine_div" class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Azienda origine</div>';
            html+='<div class="item-input-wrap">';
              html+='<input id="codice_azienda_origine" type="text" name="name" placeholder="inserire il codice dell\'azienda di origine">';
            html+='</div>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Cod. animale</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="codice_azienda" type="text" name="name" placeholder="Cod. animale" required validate>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li>';
        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Codice BDN</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="codice_bdn" type="text" name="name" placeholder="Codice BDN">';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li>';
        html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Scrapie</div>';
              html+='<div class="item-input-wrap">';
                html+='<select id="scrapie">';

                  html+=` <option value=""></option>
                          <option value="ARR/ARR">ARR/ARR</option>
                          <option value="ARR/ARH">ARR/ARH</option>
                          <option value="ARR/AHQ">ARR/AHQ</option>
                          <option value="ARQ/ARR">ARQ/ARR</option>
                          <option value="ARQ/ARQ">ARQ/ARQ</option>
                          <option value="ARQ/AHQ">ARQ/AHQ</option>
                          <option value="AHQ/AHQ">AHQ/AHQ</option>
                          <option value="ARQ/ARH">ARQ/ARH</option>
                          <option value="ARH/ARH">ARH/ARH</option>
                          <option value="AHQ/ARH">AHQ/ARH</option>
                          <option value="VRQ/VRQ">VRQ/VRQ</option>
                          <option value="VRQ/ARQ">VRQ/ARQ</option>
                          <option value="VRQ/ARH">VRQ/ARH</option>
                          <option value="VRQ/AHQ">VRQ/AHQ</option>
                          <option value="VRQ/ARR">VRQ/ARR</option>
                  `;
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Sesso</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="sesso">';
                  html+='<option value="M">Maschio</option>';
                  html+='<option value="F" selected>Femmina</option>';
                html+='</select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Razza</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="razza"></select>';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data di nascita</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_di_nascita" type="text" placeholder="Data verbale" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li id="ingresso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Data ingresso</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_in" type="text" placeholder="Data verbale" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Padre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="padre"></select>';
                html+='<input class="disabled-if-rimosso" style="display:none;" id="disabled_padre" type="text" name="name" placeholder="Inserisci padre">';
              html+='</div>';

              html+='<i id="padre_change" class="if-not-rimosso f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li>';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Madre</div>';
              html+='<div class="item-input-wrap">';
                html+='<select class="disabled-if-rimosso" id="madre"></select>';
                html+='<input class="disabled-if-rimosso" style="display:none;" id="disabled_madre" type="text" name="name" placeholder="Inserisci madre">';

              html+='</div>';

              html+='<i id="madre_change" class="if-not-rimosso f7-icons">pencil_circle</i>';

            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-not-rimosso">';
          html+='<div class="item-content item-input">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label" style="font-size: 17px;">Rimuovi per</div>';
              html+='<p id="motivo_out" class="segmented segmented-raised">';
                html+='<button class="button">Macellazione</button>';
                html+='<button class="button">Vendita</button>';
                html+='<button class="button">Morte</button>';
              html+='</p>';

              html+='<a id="elimina_definitivamente" style="color:#acacac;"><u><i>Elimina definitivamente<i></u></a>';

            html+='</div>';
          html+='</div>';
        html+='</li>';


        html+='<li style="display:none" class="if-rimosso">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
              html+='<div class="item-title item-label">Rimosso per</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="motivo_out" id="motivo_out" type="text" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li style="display:none" class="if-rimosso" id="uscita">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Rimosso il</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="data_out" type="text" placeholder="Data rimozione" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-rimosso if-macello" id="_macello">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Macello</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="macello" type="text" placeholder="Macello" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';
        html+='<li style="display:none" class="if-rimosso if-macello" id="_peso_carcassa">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Peso carcassa</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="peso_carcassa" type="text" placeholder="Peso carcassa" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li style="display:none" class="if-rimosso if-morte" id="_motivo_morte">';
          html+='<div class="item-content item-input inline-label">';
            html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Motivo morte</div>';
              html+='<div class="item-input-wrap">';
                html+='<input class="disabled-if-rimosso" id="motivo_morte" type="text" placeholder="Motivo morte" readonly="readonly" />';
              html+='</div>';
            html+='</div>';
          html+='</div>';
        html+='</li>';

        html+='<li class="item-content item-input inline-label">';
          html+='<div class="item-inner">';
            html+='<div class="item-title item-label">Note</div>';
            html+='<div class="item-input-wrap">';
              html+='<textarea id="note" placeholder="Le tue note"></textarea>';
            html+='</div>';
          html+='</div>';
        html+='</li>';



    html+='</ul>';
  html+='</div>';

  html+=`
    <div class="block block-strong inset">
      ${getPhotoHtml(guid)}
    </div>
  `;


  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);


  fillPhotos(guid);

  jQuery("#padre_change,#madre_change").click(function() {
    var gen=jQuery(this).attr('id').split('_')[0];

    jQuery('#'+gen).attr('id','tmp_disabled_'+gen);
    jQuery('#disabled_'+gen).attr('id',gen);
    jQuery('#tmp_disabled_'+gen).attr('id','disabled_'+gen);

    jQuery('#disabled_'+gen).hide();
    jQuery('#'+gen).show();

    if (jQuery(this).html()=='pencil_circle') {
      jQuery(this).html('pencil_circle_fill');
    }
    else {
      jQuery(this).html('pencil_circle');
    }
  });


  var s=storage.get();
  var options='';

  console.log("aa"+s.res);
  jQuery.each(s.res.breeds.data,function(k,v){
    options+='<option value="'+v.cod_breed+'">'+v.breed+'</option>';
  });
  jQuery('#razza').html(options);



  var button='<button style="display:none" id="salva" class="if-not-rimosso button button-fill color-green">Salva</button>';
  jQuery('#footer').html(button);

  jQuery("#elimina_definitivamente").click(function() {
    var animal = db.get_animal_from_guid(guid, id_farm);
    animal.deleted=1;
    db.save_animal(animal);

    db.updateAnimal(animal, function(){
      history.back();
    });
  });

  jQuery("#motivo_out button").unbind().click(function() {
    jQuery('#motivo_out button').removeClass('button-active');
    jQuery(this).addClass('button-active');

    location.hash="farm/"+id_farm+"/anagrafe/advanced/remove/"+guid+"/"+jQuery(this).text();
  });

  getAnimal(guid, id_farm);

  jQuery("#salva").click(function() {


    jQuery('#anagrafe-edit').find('input').each(function(){
      global.app.input.validate(this);
    });

    if (jQuery('.input-invalid').length==0) {
      var animal={
        guid:guid,
        id_farm:id_farm,
        code:jQuery('#codice_azienda').val(),
        code_bdn:jQuery('#codice_bdn').val(),
        sex:jQuery('#sesso').val(),
        scrapie:jQuery('#scrapie').val(),
        breed:jQuery('#razza').val(),
        date_birth:jQuery('#data_di_nascita').val(),
        date_in:jQuery('#data_in').val(),
        type_in:jQuery('#motivazione').val(),
        farm_in:jQuery('#codice_azienda_origine').val(),
        guid_father:jQuery('#padre').val(),
        guid_mother:jQuery('#madre').val(),
        note:jQuery('#note').val()
      };

      db.updateAnimal(animal, function(){
        history.back();
        db.storeImages();
      });
    }
    else {
      global.app.dialog.alert("Compila tutti i campi obbligatori per procedere con il salvataggio.", "Campi non compilati");
    }
  });

  var calendarIn = global.app.calendar.create({
    inputEl: '#data_in',
    dateFormat: 'yyyy-mm-dd',
    closeOnSelect:true
  });

  var calendarBirth = global.app.calendar.create({
    inputEl: '#data_di_nascita',
    dateFormat: 'yyyy-mm-dd',
    closeOnSelect:true
  });
}

export function anagrafe_view(guid,id_farm){
  console.log(id_farm);
  var s=storage.get();

  var html='';

  html+="<div class='block-title'>Riassunto animale</div>";
  html+='<div class="card">';
    html+='<div class="card-content card-content-padding">';

      html+='<div class="row">';
        html+='<div class="col">';

          html+='<div class="list">';
            html+='<ul>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Codice animale</div>';
                      html+='<span id="codice_azienda"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Sesso</div>';
                      html+='<span id="sesso"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
            html+='</ul>';
          html+='</div>';

        html+='</div>';
        html+='<div class="col">';

          html+='<div class="list">';
            html+='<ul>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Razza</div>';
                      html+='<span id="razza"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
              html+='<li>';
                html+='<div class="item-content">';
                  html+='<div class="item-inner">';
                    html+='<div class="item-title">';
                      html+='<div class="item-header">Età</div>';
                      html+='<span id="eta"></span>';
                    html+='</div>';
                  html+='</div>';
                html+='</div>';
              html+='</li>';
            html+='</ul>';
          html+='</div>';

        html+='</div>';
      html+='</div>';

      html+='<p id="note"></p>';
    html+='</div>';
    html+='<div class="card-footer"><button id="edit_anagrafica" class="col button button-small">Modifica</button></div>';
  html+='</div>';

  html+="<div class='block-title'>Aggiungi evento</div>";

  html+='<div class="card">';
    html+='<div class="card-content card-content-padding">';

      html+='<p class="custom_tabs segmented">';
      var animal = db.get_animal_from_guid(guid, id_farm);
      if (s.res.custom_form) {
        jQuery.each(s.res.custom_form.data,function(k,v){
          var isValid=true;
          if (typeof v.valid_for!='undefined' && v.valid_for!=null && v.valid_for!='' ) {
            if (v.valid_for!=animal.sex) {
              isValid=false;
            }
          }
          if (isValid) {
            html+='<button id="'+v.custom_form+'" class="button button-outline">'+v.form_name+'</button>';
          }
        });
      }
      html+='</p>';

      html+='<div id="custom_form">';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  html+='<div style="display:none;" class="timeline-block">';
    html+="<div class='block-title'>Elenco degli eventi</div>";
    html+='<div class="card">';
      html+='<div class="card-content card-content-padding">';
        html+='<div class="timeline"></div>';
      html+='</div>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  // var button='<button id="salva" class="if-not-rimosso button button-fill color-green">Salva</button>';
  // jQuery('#footer').html(button);
  var button='<button id="salva_form" class="if-not-rimosso button button-fill color-green">Salva</button>';
  button+='<button style="display:none;" id="annulla_eliminazione" class="if-rimosso button button-fill color-orange">Annulla eliminazione</button>';
  jQuery('#footer').html(button);


  var oForm=null;
  jQuery(".custom_tabs button").click(function() {
    jQuery('.custom_tabs button').removeClass('button-active');
    jQuery(this).addClass('button-active');

    var id=jQuery(this).attr('id');

    jQuery.each(s.res.custom_form.data,function(k,v){
      if (id==v.custom_form) {
        console.log(animal.sex);

        if(typeof v.json !=='undefined' ){
          jQuery('#custom_form').html('');
          var json=JSON.parse(v.json);
          var fields = json;
          var f = {};
          f.table_name = "klean";
          f.primary_key = Array('id');
          f.fields = fields;
          jQuery.each(f.fields, function(k1,v1){
            if(k1=='date'){
              v1.default=getTodayDate();
            }
          });

          // Col tema Framework7Theme non salva bene i dati col getValue perché aggiungiamo dei div..  jQuery(this.widget)[0].firstElementChild.value
          // var theme_f7=new Dbmng.AppTheme();
          var theme_f7=new Dbmng.Framework7Theme();

          oForm = new Dbmng.Form ({'aForm':f, theme:theme_f7});
          jQuery('#custom_form')[0].appendChild(oForm.createForm({}));

          global.oForm=oForm;
          jQuery('#custom_form').find('.label-form').addClass('field_separator');
        }
      }
    });
  });

  var last_event=db.getLastEventType()
  if(last_event){
    jQuery(".custom_tabs #"+last_event).click();
  }
  else{
    jQuery(".custom_tabs button").first().click();
  }


  jQuery("#annulla_eliminazione").click(function() {
    console.log("ANNULLO ELIMINAZIONE");
    var from_deleted=true;
    var animal = db.get_animal_from_guid(guid, id_farm,from_deleted);
    animal.date_out=null;
    animal.type_out=null;
    animal.farm_out=null;
    animal.sync=false;
    db.save_animal(animal);

    db.updateAnimal(animal, function(){
      history.back();
      location.reload();
    });
  });

  jQuery("#salva_form").click(function() {
    var haveData=false;
    jQuery.each(jQuery('.real_widget'),function(k,v){
        if (jQuery(v).attr('type')!=='date' && jQuery(v).val()!=='') {
          console.log(jQuery(v).val());
          haveData=true;
        }
    });
    console.log("HAVE: "+haveData);
    if (haveData) {

      //1. get animal from guid
      //create function get_animal_from_guid(guid);
      var animal = db.get_animal_from_guid(guid, id_farm);

      //fix the json of the animal (legacy)
      animal.json=get_json_fixed(animal.json);

      //get animal events
      var events=animal.json.events;

      //get event object
      var new_event=global.oForm.getValue();
      new_event.type=jQuery('.button-active').attr('id');
      //check if needs to update
      if(global.oForm.do_update){
        new_event.guid=global.oForm.do_update;

        //find the position of the events to update
        var event_position=-1;
        for (var k=0; k<events.length; k++){
          if(events[k].guid==new_event.guid){
            event_position=k;
            break;
          }
        }

        //if guid is not found insert a new event
        if(event_position>=0){
          events[event_position]=new_event;
        }
        else{
          events.push(new_event);
        }

      }
      else{
        new_event.guid=getGuid();
        //aggiunge il nuovo evento
        events.push(new_event);
      }

      db.save_animal(animal);

      db.updateAnimal(animal, function(){

        //TODO add last
        db.saveLastEventType(new_event.type);
        location.reload();
      });


    }
    else {
      history.back();
    }
  });

  jQuery("#edit_anagrafica").click(function() {
    location.hash="farm/"+id_farm+"/anagrafe/advanced/"+guid;
  });

  getAnimal(guid, id_farm);
}




//va funzione prende il vecchio oggetto json (era un array con il nuovo (oggetto con elemento events))
export function get_json_fixed(json_to_fix){
  var json_fix={};
  if(typeof  json_to_fix=='undefined'){
    json_fix={"events": []};
  }
  else if (typeof  json_to_fix=='string'){
    if (json_to_fix === '[]') {
      json_fix={"events": []};
    }
    else {
      json_fix=JSON.parse( json_to_fix);
    }
  }
  else if (typeof json_to_fix=='object') {
    if (JSON.stringify(json_to_fix) === '[]') {
      json_fix={"events": []};
    }
    else {
      json_fix=json_to_fix;
    }
  }

  if(Array.isArray(json_fix)){
    jQuery.each(json_fix, function(k,v){
      if(typeof v.guid=='undefined'){
        v.guid=getGuid();
      }
    });
    json_fix={"events": json_fix};
  }
  return json_fix;
}

export function parti_advanced(id_farm){
  var s=storage.get();
  console.log("Parti dedicata");
  var html='';
  html+='<div class="data-table data-table-init card">';
    html+='<div class="card-header">';
      html+='<div style="display:contents;"><i class="f7-icons">search</i><input type="text" id="search" placeholder="'+t('search_in_table')+'" title="Digita qui"></div>';
    html+='</div>';
    html+='<div class="card-content">';
      html+='<table id="parti_table">';
        html+='<thead>';
          html+='<tr id="animal">';
            html+='<th></th>';
            html+='<th class="label-cell sortable-cell">Codice</th>';
            html+='<th class="label-cell sortable-cell">Mesi dall\'ultimo parto</th>';
            html+='<th class="label-cell sortable-cell">Agnelli fatti</th>';
            html+='<th class="label-cell sortable-cell">Numero di parti</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="animals_tbody">';

        var animals=s['data_'+id_farm].animals.data;


        jQuery.each(animals,function(k,v){
          if (!v.deleted && v.date_out==null) {
            var age=getAge(v.date_birth);

            var years=age.years;
            var months=age.months;
            var completeAge=years+"-"+months;

            var status="";
            if (years>0) {
              status="adult";
            }
            else if (months>6) {
              status="adult";
            }
            else {
              status="baby";
            }

            var sex='';
            if (v.sex!=null) {
              sex=v.sex;
            }

            if (sex.toLowerCase()+'_'+status=='f_adult') {
              var n_agnelli=0;
              var n_parti=0;
              var last_parto='<span class="badge color-red">Nessun dato</span>';

              var events=v.json;
              if (typeof v.json=='string') {
                events=JSON.parse(v.json);
              }
              events=events.events;
              console.log(events);

              jQuery.each(events,function(k,v){
                if (v.type=='delivery') {
                  console.log(v);
                  if (v.numbers_f!='') {
                    n_agnelli+=parseInt(v.numbers_f);
                  }
                  if (v.numbers_m!='') {
                    n_agnelli+=parseInt(v.numbers_m);
                  }
                  n_parti++;

                  if (last_parto==='<span class="badge color-red">Nessun dato</span>') {
                    last_parto=v.date;
                  }
                  else {
                    if (v.date>last_parto) {
                      last_parto=v.date;
                    }
                  }
                }

              });

              if (last_parto!=='<span class="badge color-red">Nessun dato</span>') {
                last_parto=monthDiff(last_parto,getTodayDate());
              }

              html+='<tr guid="'+v.guid+'" onclick="location.hash=\'farm/'+id_farm+'/anagrafe/advanced/view/'+v.guid+'\'">';
                html+='<td>';
                  html+='<img src="icons/'+sex.toLowerCase()+'_'+status+'.svg" width="40"/>';
                html+='</td>';
                html+='<td style="text-align:center;" class="numeric-cell">'+checkNull(v.code)+'</td>';
                html+='<td style="text-align:center;" class="label-cell">'+last_parto+'</td>';
                html+='<td style="text-align:center;" class="numeric-cell">'+n_agnelli+'</td>';
                html+='<td style="text-align:center;" class="numeric-cell">'+n_parti+'</td>';
              html+='</tr>';
            }
          }
        });

        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  searchInTable('parti_table');
}

function monthDiff(d1, d2) {
  var months;
  d1=new Date(d1);
  d2=new Date(d2);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function anagrafe_advanced(id_farm){
  var html='';

  html+="<div style='margin-top:15px;' class='block-title block-title-medium central'>"+t('advanced_registry')+"<br><span id='farm_name'></span></div>";
  // html+="<div class='block-title block-title central'>Anagrafica avanzata</div>";

  html+='<div class="show-nt-if-num-eq-0" style="display:none;">';
    html+='<div class="data-table data-table-init card">';
      html+='<div class="card-header">';
        html+='<div class="row">';
          html+='<h3>Non ci sono ancora dati inseriti! Aggiungi subito il tuo primo dato! </h3>';
        html+='</div>';
      html+='</div>';
    html+='</div>';

    html+='<div style="margin-top:'+(window.innerHeight-460)+'px" class="arrow-down">';
      html+='<i style="margin: auto; font-size:80px;" class="f7-icons">arrow_down</i>';
    html+='</div>';

  html+='</div>';

  html+='<div style="display:none;" class="show-nt-if-num-sup-0 data-table data-table-init card">';
  // html+='<div style="display:none; width:100%" class="show-nt-if-num-sup-0 card">';
    html+='<div class="card-header">';
      html+='<div style="display:contents;"><i class="f7-icons">search</i><input type="text" id="search" placeholder="'+t('search_in_table')+'" title="Digita qui"></div>';
    html+='</div>';
    html+='<div class="card-content">';
      html+='<table id="animal_table">';
        html+='<thead>';
          html+='<tr id="animal">';
            html+='<th></th>';
            html+='<th data-field="code" style="width:25%; text-align:left" class="label-cell sortable-cell">Codice</th>';
            html+='<th data-field="date_birth" style="width:25%; text-align:left" class="label-cell sortable-cell">Età</th>';
            html+='<th data-field="sex" style="width:25%; text-align:left" class="label-cell sortable-cell">Sesso</th>';
            html+='<th data-field="breed" style="width:25%; text-align:left" class="label-cell sortable-cell">Razza</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="animals_tbody">';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';


    html+=`
    <div id="pagination_space" class="data-table-footer">
    <!--
      <div class="data-table-rows-select">
        Per page:
        <div class="input input-dropdown">
          <select>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="all">All</option>
          </select>
        </div>
      </div>
    -->
    <div class="data-table-pagination">
      <span class="data-table-pagination-label"><span class='start'>1</span>-<span class='end'>25</span> di <span class='max'></span></span>
      <a data-page="0" class="prev link disabled">
        <i class="icon icon-prev color-gray"></i>
      </a>
      <a data-page="1" class="next link">
        <i class="icon icon-next color-gray"></i>
      </a>
    </div>
  </div>
    `;
  html+='</div>';


  html+='<div style="display:none;" class="show-if-num-sup-0 data-table data-table-init card">';
    html+='<div class="card-header">';
      html+='<div>Capi non più in azienda (<span id="num_eliminati_recente">0</span>)</div>'; //<i class="f7-icons">trash</i>
    html+='</div>';
    html+='<div class="card-content">';
      html+='<table id="recently_deleted_table">';
        html+='<thead>';
          html+='<tr id="recently_deleted">';
            html+='<th class="label-cell sortable-cell">Codice</th>';
            html+='<th class="label-cell sortable-cell">Motivo uscita</th>';
            html+='<th class="label-cell sortable-cell">Data di uscita</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="recently_deleted_tbody">';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var button='<button id="aggiungi_animale" class="button button-fill">Aggiungi</button>';
  jQuery('#footer').html(button);

  jQuery("#aggiungi_animale").click(function() {
    location.hash="#farm/"+id_farm+"/anagrafe/advanced/new";
  });

  db.sync(id_farm,function(data){
    console.log(id_farm);
    drawTableAnimals(data, id_farm);
  });
}

//Disegna la tabella degli animali
function drawTableAnimals(data, id_farm){


  // jQuery('*').css('transition', 'none');
  // jQuery('*').css('animation', 'none');

    if (data.recently_deleted.rowCount>0) {
      jQuery('.show-if-num-sup-0').show();
    }

    if (data.animals.rowCount==0) {
      jQuery('.show-nt-if-num-eq-0').show();
    }
    else {
      jQuery('.show-nt-if-num-sup-0').show();
    }

  //TODO A che cosa serve?
  try {
    if (id_farm) {
      jQuery('#farm_name').html(data.farm.data[0].name);
    }
  }
  catch (e) {
    console.log(e);
  }

  if (data.animals.ok) {
    var html='';

    jQuery.each(data.recently_deleted.data,function(k,v){
      html+='<tr class="actions-cell" guid="'+v.guid+'">';
        html+='<td class="numeric-cell">'+v.code+'</td>';
        html+='<td class="label-cell">'+v.type_out+'</td>';
        html+='<td class="numeric-cell">'+v.date_out+'</td>';
      html+='</tr>';
    });
    jQuery('#recently_deleted_tbody').html(html);


    //Funzionje per paginare i record.
    var pagination_record=30;


    jQuery(".sortable-cell").click(function() {
      if (jQuery(this).hasClass('sortable-cell-active')) {
        if (jQuery(this).hasClass('sortable-asc')) {
          jQuery(this).removeClass('sortable-asc').addClass('sortable-desc');
        }
        else {
          jQuery(this).removeClass('sortable-desc').addClass('sortable-asc');
        }
      }
      else {
        jQuery('.sortable-cell').removeClass('sortable-cell-active');
        jQuery(this).addClass('sortable-cell-active sortable-asc');
      }

      var dir;
      // var n=jQuery('.sortable-cell-active')[0].cellIndex;

      // data-field="sex"
      // var n_text=jQuery('.sortable-cell-active')[0].innerText;


      var field_to_sort=jQuery('.sortable-cell-active').attr("data-field");

      if (jQuery('.sortable-cell-active').hasClass('sortable-asc')) {
        dir='asc';
      }
      else {
        dir='desc';
      }

      // var table_id=jQuery(this).parent().attr('id')+'_table';
      // var haveToParse=false;

      // if (n==2 && n_text=='Età') {
      //   haveToParse=true;
      // }

      // console.log(table_id,n,dir,haveToParse);

      //TODO
      // var field_to_sort="code";

      var cresce=1;
      var decresce=-1;
      if(dir=='desc'){
        cresce=-1; decresce=1;
      }

      var animals_to_draw=data.animals.data.sort((a, b) => (a[field_to_sort] < b[field_to_sort]) ? cresce : decresce);
      addAnimalToTable(animals_to_draw.slice(0,pagination_record), id_farm);
      //sortTable(table_id,n,dir,haveToParse);
    });


    var num_animals=data.animals.data.length
    var ratio=num_animals/pagination_record;

      // for(var x=0; x<ratio; x++){
      //   jQuery("#pagination_space").append(` <button data-page=${x} class="pag">${x+1}</button>&nbsp;`);
      // }


      jQuery("#pagination_space .next, #pagination_space .prev" ).on("click", function(){

        var page=parseInt(jQuery(this).attr("data-page"));

        var start=page*pagination_record;
        var end=start+pagination_record;

        if(end>=num_animals){
          end=num_animals;
          jQuery("#pagination_space .next").attr("data-page",page).addClass("disabled");
        }
        else{
          jQuery("#pagination_space .next").attr("data-page",page+1).removeClass("disabled");;
        }

        jQuery("#pagination_space .start").html(start+1);
        jQuery("#pagination_space .end").html(end);
        jQuery("#pagination_space .max").html(num_animals);


        if(page>0){
          jQuery("#pagination_space .prev").attr("data-page",page-1).removeClass("disabled");
        }
        else{
          jQuery("#pagination_space .prev").attr("data-page",0).addClass("disabled");;
        }


        var animals_to_draw=data.animals.data.slice(start, end);

        addAnimalToTable(animals_to_draw, id_farm);
      });


      if(ratio<1){
        jQuery("#pagination_space").hide();
      }



    jQuery("#pagination_space .max").html(num_animals);

    var animals_to_draw=data.animals.data.slice(0, pagination_record);
    addAnimalToTable(animals_to_draw, id_farm);

    jQuery('#num_eliminati_recente').html(data.recently_deleted.rowCount);



    // jQuery(".actions-cell").click(function() {
    //   location.hash="anagrafe/advanced/remove/"+jQuery(this).attr('guid');
    // });

    // searchInTable('animal_table');

    //Comunque filtra i primi 25 (altrimenti si blocca se sono molti)
    searchInTableData('animal_table', data.animals.data, id_farm, pagination_record);


  }
  else {
    alert("C'è un errore che non dipende da te. Contatta chi gestisce l'APP");
  }
}


function addAnimalToTable(animals, id_farm){

  var html="";

  jQuery.each(animals,function(k,v){
    if (!v.deleted && v.date_out==null ) {
      var age=getAge(v.date_birth);

      var years=age.years;
      var months=age.months;
      var completeAge=years+"-"+months;

      var status="";
      if (years>0) {
        status="adult";
      }
      else if (months>6) {
        status="adult";
      }
      else {
        status="baby";
      }

      var sex='';
      if (v.sex!=null) {
        sex=v.sex;
      }
      html+='<tr class="actions-cell" guid="'+v.guid+'">';
        html+='<td>';
          html+='<img src="icons/'+sex.toLowerCase()+'_'+status+'.svg" width="40"/>';
        html+='</td>';
        html+='<td  >'+checkNull(v.code)+'</td>';
        html+='<td  >'+completeAge+'</td>';
        html+='<td  >'+sex+'</td>';
        html+='<td  >'+v.breed+'</td>';
      html+='</tr>';
    }
    else if (v.date_out!=null) {
      // var s=storage.get();
      var recDeleted='';
      recDeleted+='<tr class="actions-cell" guid="'+v.guid+'">';
        recDeleted+='<td class="numeric-cell">'+v.code+'</td>';
        recDeleted+='<td class="label-cell">'+v.type_out+'</td>';
        recDeleted+='<td class="numeric-cell">'+v.date_out+'</td>';
      recDeleted+='</tr>';
      jQuery('#recently_deleted_tbody').append(recDeleted);
      // s['data_'+v.id_farm].recently_deleted.data.push(v);
      // storage.save(s);
    }
  });
  jQuery('#animals_tbody').html(html);


  jQuery(".actions-cell").click(function() {
    location.hash="farm/"+id_farm+"/anagrafe/advanced/view/"+jQuery(this).attr('guid');
  });



}


function checkNull(a){
  if(a==null){
    return "";
  }
  else{
    return a;
  }
}

//la vecchia versione (ch e cercava nell html non funziona più)
function searchInTable(table_id){
  var rows = jQuery('#'+table_id+' tr');
  jQuery('#search').keyup(function() {
    var val = jQuery.trim(jQuery(this).val()).replace(/ +/g, ' ').toLowerCase();

    rows.show().filter(function() {
      if (typeof jQuery(this).parent()[0] !=='undefined' && jQuery(this).parent()[0].localName=='thead') {
        return false;
      }
      else {
        var text = jQuery(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }
    }).hide();
  });
}


//search in data and then render the table (needs for pagination)
function searchInTableData(table_id, animals, id_farm, pagination_record){
  // var rows = jQuery('#'+table_id+' tr');
  jQuery('#search').keyup(delay(function() {
    var val = jQuery.trim(jQuery(this).val()).replace(/ +/g, ' ').toLowerCase();
    if(val.length>2){

      var a=animals.filter(function(an) {
        var text=(an.code_bdn+" "+an.code).toLowerCase();
        return text.indexOf(val)>-1;
      });
      addAnimalToTable(a.slice(0,pagination_record), id_farm);
    }
    else{
      addAnimalToTable(animals.slice(0,pagination_record), id_farm);
    }

  },500));
}


//funzione per attendere uin po prima di cercare
function delay(callback, ms) {
  var timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}



export function getTodayDate(format){
  var today=new Date();

  var today_yymmdd=new Date(today).getFullYear()+"-"+addZero(new Date(today).getMonth()+1)+"-"+addZero(new Date(today).getDate());
  today=addZero(new Date(today).getDate())+"/"+addZero(new Date(today).getMonth()+1)+"/"+addZero(new Date(today).getFullYear());
  if (typeof format!='undefined') {
    if (format=='dd/mm/yyyy') {
      return today;
    }
    else if (format=='yyyy-mm-dd') {
      return today_yymmdd;
    }
    else {
      alert('Formato non ancora sviluppato.');
      return today_yymmdd;
    }
  }
  else {
    return today_yymmdd;
  }
}

export function addZero(i) {
  if (i < 10) {
      i = "0" + i;
  }
  return i;
}

function drawParents(parents, id_farm){
  console.log("parents",parents);

  var html_f='<option value=0></option>';
  var html_m='<option value=0></option>';

  jQuery.each(parents,function(k,v){
    if (v.sex=='F') {
      html_f+='<option value="'+v.guid+'">'+v.code+'</option>';
    }
    else {
      html_m+='<option value="'+v.guid+'">'+v.code+'</option>';
    }
  });

  jQuery('#padre').html(html_m);
  jQuery('#madre').html(html_f);
}

export function getAge(dateString) {
  var now = new Date();
  var today = new Date(now.getYear(),now.getMonth(),now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(dateString);

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var monthAge;

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob)
    monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow -monthDob;
  }

  var age = {
    years: yearAge,
    months: monthAge
  };

  return age;
}


//prende il singolo animale
function getAnimal(guid,id_farm){
  db.getSingleAnimal(guid, id_farm, function(data){
    drawSingleAnimal(data,id_farm);
  });
}

// Funzione chiamata in modifica dell'animale e serve a riempire/disegnare la sua storia(timeline) e i dati(select dei parents ad esempio)..
function drawSingleAnimal(data,id_farm){
  var s=storage.get();
  var json=get_json_fixed(data.animals.data[0].json);

  //TODO intercettare che data.animals abbia almeno un records
  //TODO Creare l'oggetto animal=data.animals.data[0] (attenzione sotto a volte si usa)
  //TODO smettere di usare data.animals.data

  try {

    var events=json.events.sort((a, b) => (a.date < b.date) ? 1 : -1);

    console.log(json.events);

    if (events.length>0) {
      jQuery('.timeline-block').show();
      var timeline='';
      jQuery.each(events,function(k,v){
        timeline+='<div class="timeline-item">';
          timeline+='<div class="timeline-item-date">'+getTimelineDate(v.date)+'</div>';
          timeline+='<div class="timeline-item-divider"></div>';
          //todo  lLEAN PERCHE C'ÈERA SCRITTO 125PX???
          // timeline+='<div class="timeline-item-content" style="width:125px;">';
          timeline+='<div class="timeline-item-content" style="width:100%;">';
            jQuery.each(s.res.custom_form.data,function(i,d){
              if (d.custom_form==v.type) {
                timeline+='<div class="timeline-item-title">'+d.form_name+'<div class="timeline-item-icon" style="color: #bbb;float:right;margin-top: 7px;">';
                //TODO fare l'update
                timeline+='<i id="'+k+'" style="padding-right:20px;"" class="update-event f7-icons">pencil</i>';
                timeline+='<i id="'+k+'" class="delete-event f7-icons">trash</i>';
                timeline+='</div></div>';
              }
            });

            if (v.type=='eco') {
              timeline+='<div class="timeline-item-subtitle">'+v.result+'</div>';
            }
            else if (v.type=='delivery') {

              if (typeof v.numbers_m!=='undefined' && v.numbers_m!='') {
                var lambLabelM='agnelli';
                if (parseInt(v.numbers_m)==1) {
                  lambLabelM='agnello';
                }
                timeline+='<div class="timeline-item-subtitle">'+v.numbers_m+' '+lambLabelM+'</div>';
              }

              if (typeof v.numbers_f!=='undefined' && v.numbers_f!='') {
                var lambLabelF='agnelle';
                if (parseInt(v.numbers_f)==1) {
                  lambLabelF='agnella';
                }
                timeline+='<div class="timeline-item-subtitle">'+v.numbers_f+' '+lambLabelF+'</div>';
              }

              if (typeof v.numbers!=='undefined' && v.numbers!='') {
                var lambLabel='agnell*';
                timeline+='<div class="timeline-item-subtitle">'+v.numbers+' '+lambLabel+'</div>';
              }


              timeline+='<div class="timeline-item-text">'+v.ram+'</div>';
            }
            else if (v.type=='monta') {
              timeline+='<div class="timeline-item-subtitle">'+v.azione+'</div>';
            }
            else if (v.type=='latte') {
              var label='';
              if (v.in_out=='in') {
                label='Ingresso in lattazione';
              }
              else {
                label='Uscita dalla lattazione';
              }
              timeline+='<div class="timeline-item-subtitle">'+label+'</div>';
            }
            else {
              var sub="";
              jQuery.each(v,function(k2,v2){
                if(["guid","type","date"].indexOf(k2)==-1){
                  sub=sub+v2+"<br/>";
                }
              });
              timeline+='<div class="timeline-item-subtitle">'+sub+'</div>';
            }
          timeline+='</div>';
        timeline+='</div>';
      });
      jQuery('.timeline').html(timeline);

      jQuery(".delete-event").click(function() {
        var k_2_del=jQuery(this).attr('id');
        events.splice(k_2_del, 1);
        // data.animals.data[0].json=JSON.stringify(events);
        console.log(data);
        var animal = db.get_animal_from_guid(data.animals.data[0].guid, id_farm);
        animal.json=(events);
          db.save_animal(animal);

          db.updateAnimal(animal, function(){
            location.reload();
          });
      });


      jQuery(".update-event").click(function() {
        var k_2_update=jQuery(this).attr('id');
        var event_to_update=events[k_2_update];

        if(event_to_update.type !== jQuery('.button-active').attr('id')){
          //update the oForm and goes on the right tab
          jQuery(".custom_tabs #"+event_to_update.type).click();
        }

        jQuery('#custom_form').html("");
        global.oForm.do_update=event_to_update.guid;
          jQuery('#custom_form')[0].appendChild(global.oForm.createForm(event_to_update));
          jQuery('#custom_form').find('.label-form').addClass('field_separator');
      });

    }
  }
  catch(error) {
    console.error(error);
  }


  //in fase di inserimento i valori sono nulli
  var date_birth=data.animals.data[0].date_birth;
  var guid_animal=data.animals.data[0].guid;
  db.getParents(id_farm, date_birth,guid_animal, function(data){
    drawParents(data, id_farm);
  });

  //Se cambia la data ricalcola i possibili genitori
  jQuery('#data_di_nascita').on("change", function(){
    date_birth=jQuery(this).val();
    db.getParents(id_farm, date_birth,guid_animal, function(data){
      drawParents(data, id_farm);
    });
  });

  //

  if(jQuery('#codice_azienda').is("span")){
    jQuery('#codice_azienda').text(data.animals.data[0].code);
  }
  if(jQuery('#razza').is("span")){
    jQuery('#razza').text(data.animals.data[0].breed);
  }
  if(jQuery('#sesso').is("span")){
    jQuery('#sesso').text(data.animals.data[0].sex);
  }
  if(jQuery('#scrapie').is("span")){
    jQuery('#scrapie').text(data.animals.data[0].scrapie);
  }

  if(jQuery('#eta').is("span")){
    var age=getAge(data.animals.data[0].date_birth);

    var years=age.years;
    var months=age.months;
    var completeAge=years+"-"+months;


    jQuery('#eta').text(completeAge);
  }
  if(jQuery('#note').is("p")){
    jQuery('#note').text(data.animals.data[0].note);
  }


  jQuery('#note').val(data.animals.data[0].note);
  jQuery('#codice_azienda').val(data.animals.data[0].code);
  jQuery('#codice_bdn').val(data.animals.data[0].code_bdn);
  jQuery('#sesso').val(data.animals.data[0].sex);
  jQuery('#scrapie').val(data.animals.data[0].scrapie);
  jQuery('#motivazione').val(data.animals.data[0].type_in);
  jQuery('#razza').val(data.animals.data[0].breed);
  jQuery('#data_in').val(data.animals.data[0].date_in);
  jQuery('#data_di_nascita').val(data.animals.data[0].date_birth);

  jQuery('#padre').val(data.animals.data[0].guid_father);
  if (jQuery('#padre').val()==null) {
    jQuery('#padre_change').click();
    jQuery('#padre').val(data.animals.data[0].guid_father);
  }

  jQuery('#madre').val(data.animals.data[0].guid_mother);
  if (jQuery('#madre').val()==null) {
    jQuery('#madre_change').click();
    jQuery('#madre').val(data.animals.data[0].guid_mother);
  }

  jQuery('#codice_azienda_origine').val(data.animals.data[0].farm_in);



  if(data.animals.data[0].type_in=='Nascita'){
    jQuery('#codice_azienda_origine_div').hide();
  }

  if (data.animals.data[0].type_out!=null) {
    jQuery('.if-not-rimosso').hide();
    jQuery('.if-rimosso').show();

    jQuery(".disabled-if-rimosso").prop( "disabled", true );


    jQuery('#data_out').val(data.animals.data[0].date_out);
    jQuery('.motivo_out').val(data.animals.data[0].type_out);

    if (data.animals.data[0].type_out=='Macellazione') {
      jQuery('.if-macello').show();
      jQuery('.if-morte').hide();

      if (data.animals.data[0].macello!=null) {
        jQuery('#macello').val(data.animals.data[0].macello);
      }
      if (data.animals.data[0].peso_carcassa!=null) {
        jQuery('#peso_carcassa').val(data.animals.data[0].peso_carcassa+" KG");
      }
    }
    else if (data.animals.data[0].type_out=='Morte') {
      jQuery('.if-macello').hide();
      jQuery('.if-morte').show();

      if (data.animals.data[0].motivo_morte!=null) {
        jQuery('#motivo_morte').val(data.animals.data[0].motivo_morte);
      }

    }
    else {
      jQuery('.if-macello').hide();
      jQuery('.if-morte').hide();
    }
  }
  else {
    jQuery('.if-not-rimosso').show();
    jQuery('.if-rimosso').hide();
  }
}

function getTimelineDate(date){
  var timelineDate='';
  var day=date.substr(8,2);
  var month=date.substr(5,2);
  var year=date.substr(0,4);

  if (month=='01') {
    month="GEN";
  }
  else if (month=='02') {
    month="FEB";
  }
  else if (month=='03') {
    month="MAR";
  }
  else if (month=='04') {
    month="APR";
  }
  else if (month=='05') {
    month="MAG";
  }
  else if (month=='06') {
    month="GIU";
  }
  else if (month=='07') {
    month="LUG";
  }
  else if (month=='08') {
    month="AGO";
  }
  else if (month=='09') {
    month="SET";
  }
  else if (month=='10') {
    month="OTT";
  }
  else if (month=='11') {
    month="NOV";
  }
  else if (month=='12') {
    month="DIC";
  }

  return day+' <small>'+month+'</small> '+year;
}



// function sortTable(table_id, n, dir, haveToParse) {
//   // console.log(haveToParse);
//   var table, rows, switching, i, x, y, shouldSwitch, switchcount = 0;
//   table = document.getElementById(table_id);
//   switching = true;
//   //Set the sorting direction to ascending:
//   /*Make a loop that will continue until
//   no switching has been done:*/
//   while (switching) {
//     //start by saying: no switching is done:
//     switching = false;
//     rows = table.rows;
//     /*Loop through all table rows (except the
//     first, which contains table headers):*/
//     for (i = 1; i < (rows.length - 1); i++) {
//       //start by saying there should be no switching:
//       shouldSwitch = false;
//       /*Get the two elements you want to compare,
//       one from current row and one from the next:*/
//       x = rows[i].getElementsByTagName("TD")[n];
//       y = rows[i + 1].getElementsByTagName("TD")[n];
//       /*check if the two rows should switch place,
//       based on the direction, asc or desc:*/
//       var xInner=x.innerHTML.toLowerCase();
//       var yInner=y.innerHTML.toLowerCase();

//       if (haveToParse) {
//         xInner = xInner.replace(/-/g, '');
//         xInner=parseFloat(xInner);

//         yInner = yInner.replace(/-/g, '');
//         yInner=parseFloat(yInner);
//       }

//       if (dir == "asc") {
//         if (xInner > yInner) {
//           //if so, mark as a switch and break the loop:
//           shouldSwitch= true;
//           break;
//         }
//       } else if (dir == "desc") {
//         if (xInner < yInner) {
//           //if so, mark as a switch and break the loop:
//           shouldSwitch = true;
//           break;
//         }
//       }
//     }
//     if (shouldSwitch) {
//       /*If a switch has been marked, make the switch
//       and mark that a switch has been done:*/
//       rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
//       switching = true;
//       //Each time a switch is done, increase this count by 1:
//       switchcount ++;
//     } else {
//       /*If no switching has been done AND the direction is "asc",
//       set the direction to "desc" and run the while loop again.*/
//       if (switchcount == 0 && dir == "asc") {
//         dir = "desc";
//         switching = true;
//       }
//     }
//   }
// }

//Crea le card per i parti
export function partiHomeCard(){
  var html='';
  html+='<div id="parti" class="card">';
    html+='<div class="card-header">Parti</div>';
    html+='<div class="icons_parti card-content card-content-padding">';
    // html+='<div class="block-title">Customization</div>';
      // html+='<div class="block block-strong">';
        html+='<div class="row">';
        html+='<div class="col text-align-center">';
          html+='<div class="mesi-36 gauge gauge-init"></div>';
        html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="mesi-24 gauge gauge-init"></div>';
          html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="mesi-12 gauge gauge-init"></div>';
          html+='</div>';
        html+='</div>';

        html+='<div class="row">';
        html+='<div class="col text-align-center">';
          html+='<div class="text-parti text-parti-mesi-36 gauge gauge-init"></div>';
        html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="text-parti text-parti-mesi-24 gauge gauge-init"></div>';
          html+='</div>';
          html+='<div class="col text-align-center">';
            html+='<div class="text-parti text-parti-mesi-12 gauge gauge-init"></div>';
          html+='</div>';
        html+='</div>';

    html+='</div>';
  html+='</div>';
  return html;
}

//Crea le card per la home page con il numero degli animali
export function anagraficaHomeCard(){
  var html='';
  html+='<div id="anagrafe" class="card">';
    html+='<div class="card-header">'+t('anagrafe')+'</div>';
    html+='<div class="card-content card-content-padding">';
      html+='<div style="display:none;" class="icons_animals row">';
        html+='<div class="col-25">';
          html+='<img src="icons/f_adult.svg" width="100%"/>';
          html+='<h3 id="f_adult" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/m_adult.svg" width="100%"/>';
          html+='<h3 id="m_adult" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/f_baby.svg" width="100%"/>';
          html+='<h3 id="f_baby" class="central"></h3>';
        html+='</div>';
        html+='<div class="col-25">';
          html+='<img src="icons/m_baby.svg" width="100%"/>';
          html+='<h3 id="m_baby" class="central"></h3>';
        html+='</div>';
      html+='</div>';


      html+='<div style="display:none; margin-top: 0;" class="list links-list icons_animals">';
        html+='<ul>';
          html+='<li>';
            html+='<a><span style="text-align: end;">'+t('access_anagrafica')+'</span></a>';
          html+='</li>';
        html+='</ul>';
      html+='</div>';

    html+='</div>';
  html+='</div>';
  return html;
}

function getGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

function getPhotoHtml(id_obersvation){
  var html='';
  html+="<div id='photo'>";
    html+='<p class="row">';
      if (!global.is_cordova()) {
        html+='<input style="display:none;" id="scatta_foto" class="scatta_foto col" type="file" accept="image/*,.pdf"></input>';
        html+='<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*,.pdf"><span>Aggiungi</span> <i class="icon f7-icons">camera_fill</i></button>';

      }
      else {
        html+='<button id="scatta_foto" class="scatta_foto col button button-large button-raised" type="file" accept="image/*,.pdf"><span>Aggiungi</span> <i class="icon f7-icons">camera_fill</i></button>';
      }
    html+='</p>';

    try {
      html+=photo.getPhotoDiv(id_obersvation);
    }
    catch (e) {
      global.crash_log(e,"photo.getPhotoDiv(id_obersvation) -");
    }
  html+="</div>";
  return html;
}

function fillPhotos(id_obersvation){
  // debugger
  photo.fillPhotoDiv(id_obersvation);

  if (global.is_cordova()) {
    jQuery("#scatta_foto").click(function() {
      photo.takePicture(id_obersvation);
    });

  }
  else {
    jQuery("#scatta_foto").change(function() {
      photo.takePicture(id_obersvation);
    });

    jQuery('#scatta_foto_btn').click(function(){
      // debugg
      jQuery("#scatta_foto").click();
    });
  }
}
