/*jshint esversion: 6 */
import global from './global.js';


export default function t(input){
  var self=this;

  var i18n=dictionary[global.language];

  var key='';
  try{
    key=input.toLowerCase().trim();
  }
  catch(e){
    key=input;
  }


  if(!i18n){
    return input;
  }
  else if (i18n=={}){
    return input;
  }
  else{
    if(i18n[key]){
      return i18n[key];
    }
    else{
      if(global.language!=='en'){
        console.log('||'+key+'|| not found');
      }
      return input;
    }
  }
}

var dictionary={
  "it": {
    "ciao":"Ciao",
    "accedi":"Accedi",
    "show_password":"Mostra password",
    "welcome_tec": "Benvenuto tecnico",
    "farm": "Azienda",
    "action": "Azione",
    "actions": "Azioni",
    "welcome": "Benvenuto",
    "access_anagrafica":"Accedi all'anagrafica",
    "anagrafe":"Anagrafe",
    "su":"su",
    "mesi":"mesi",
    "parti":"parti",
    "search_in_table":"Cerca in tabella..",
    "advanced_registry":"Anagrafica avanzata",
    "chose_language": "Scegli la tua lingua",
    "synchronize_data": "Sincronizza i dati",
    "back_home_page": "Torna alla Home Page",
    "impostazioni": "Impostazioni",
    "versione":"Versione",
    "rams_choice":"Scelta degli arieti",
    "ram_message":"Da questa funzione è possibile vedere la compatibilità del proprio gregge con gli arieti degli altri utenti e contattarne i proprietari.",
    "go_to_choice":"Vai alla scelta",
    "back":"Indietro",

    // OLIVE pheno aeco
    'winter dormancy':'gemme invernali',
    'start bud break':'gemme gonfie',
    // 'bud break':'gemme aperte',
    'leaf development':'sviluppo fogliare',
    'shoot development':'accrescimento germoglio',
    'inflorescence buds are closed':'comparsa grappoli fiorali',
    'inflorescence buds start to swell':'rigonfiamento bottoni fiorali',
    'corolla differentiation':'differenziazione corolla',
    'beginning of flowering':'inizio fioritura',
    'full flowering':'fioritura 50%',
    'fruit set':'allegagione',
    'fruit growth after fruit set':'accresc. frutto post allegagione',
    'stone hardening 0-50%':'indurimento del nocciolo (0-50%)',
    'stone hardening >50%':'indurimento del nocciolo (>50%)',
    'stone hardening 100%':'nocciolo indurito',
    'fruit growth green olives':'accrescimento frutto olive verdi',
    'fruit growth yellow-green olives':'accrescimento frutto olive giallo verdi',
    'veraison 5%':'Inizio Invaiatura 5%',
    'veraison 50%':'invaiatura 50%',
    'veraison 100%':'invaiatura 100%',

    // Grape pheno aeco
    'bud break':'germogliamento',
    'beginning leaf unfolded':'prime foglie distese',
    '5 leaves unfolded':'tralci lunghi 10 cm',
    'inflorescence visible':'grappoli visibili',
    'inflorescence swelling':'grappoli distesi',
    'flowers separating':'bottoni fiorali separati',
    'beginning of flowering (10%)':'inizio fioritura (10%)',
    'full flowering (50%)':'piena fioritura (50%)',
    'fruit set (10%)':'inizio allegagione 10%',
    'fruit set (50%)':'allegagione 50%',
    'fruit set (100%)':'allegagione completa',
    'berries groat-sized':'acino grano di pepe',
    'berries pea-sized':'acino pisello',
    'berries beginning to touch':'prechiusura grappolo',
    'berries touching':'grappolo chiuso',
    'beginning of ripening':'inizio invaiatura',
    'berries developing colour':'invaiatura 50%',
    'softening of berries':'invaiatura completa',
    'maturation':'maturazione',
    'agronomist':'Agronomo/Consulente',
    'num_no':'Olive punturate',
    'num_si':'Olive sane',
  },
  "en": {
    '':'',
    '':'',
    '':'',
    '':'',
    '':'',
    '':'',
    '':'',
    '':'',
    'infezioni secondarie':'secondary infections',
    'infezioni primarie e':'primary infections and',
    'ci sono state':'Completed infections:',
    'ci sono delle infezioni in corso':'There are ongoing infections',
    // 'ci sono delle infezioni in atto':'There are infections taking place',
    'non ci sono infezioni':'There are no infections',
    'adulti':'Adults',
    'crisalidi':'Chrysalises',
    'larve':'Larvae',
    'uova':'Eggs',
    'irrigazioni effettuate':'Irrigations done',
    'periodo':'Period',
    'pioggia':'Rain',
    'etc cumulata':'ETc total',
    'etc media':'ETc average',
    'ultimi 7 giorni':'Last 7 days',
    'girasole':'Sunflower',
    'pomodoro':'Tomato',
    'cece':'Chickpea',
    'orzo':'Barley',
    'frumento duro':'Durum wheat',
    'pisello':'Pea',
    'lenticchia':'Lentil',
    'soia':'Soybean',
    'dss mosca':'Fly DSS',
    'concimazione':'Fertilization',
    'oidio della vite':' Powdery mildew (Erisyphe necator)',
    'plasmopara della vite':'Downy mildew (Plasmopara viticola)',
    'lobesia della vite':'European grapevine moth (Lobesia botrana)',
    'basso rischio':'Low risk',
    'allerta':'Alert',
    'alto rischio':'High risk',
    'ottimo lavoro! mica tutti arrivano a leggere questo messaggio!':'Good job! Not everyone gets to read this message!',
    'a questo punto che fai? non ci arrivi a 100 olive?!':'What are you doing at this point? Can\'t you get to 100 olives ?!',
    'forza dai! ancora qualche oliva':'Come on! A few more olives',
    'osserva almeno 50 olive':'Look at at least 50 olives',
    'osserva la tua prima oliva':'Look at your first olive',
    'olive osservate':'olives observed',
    'punture su':'stings on',
    'infestazione al':'Infestation at',
    'puntura si':'YES Punctured',
    'puntura no':'NO Punctured',
    'num_no':'Punctured olives',
    'num_si':'Healthy olives',
    'catture':'Catches',
    'non c\'è nessuna scheda di monitoraggio per questa coltura':'There is no monitoring for this crop',
    'c\'è stato un errore nel caricamento dei dati statici.':'There was an error loading static data.',
    'caricamento in corso..':'Loading..',
    'chiudi':'Close',
    'seleziona':'Select',
    'clicca su':'Click on',
    'aggiungi alla schermata home':'Add to Home Screen',
    'installa':'Install',
    'dispositivi con attrattivi alimentari e/o feromonici e/o avvelenati con deltametrina':'Devices with food and / or pheromone attractants and / or poisoned with deltamethrin',
    'altro biologico':'Other organic',
    'dispositivi ad azione attract and kill':'Attract and kill action devices',
    'caolino':'Kaolin',
    'rame':'Copper',
    'altro larvicida':'Other Larvicida',
    'cattura massale':'Mass capture',
    'piretrine':'Pyrethrins',
    'spinosad con specifica esca':'Spinosad with specific lure',
    'esca proteica + acetamiprid':'Protein bait + acetamiprid',
    'aratura':'Plowing',
    'ripuntatura':'Subsoiling',
    'erpicatura':'Harrowing',
    'rullatura':'Rolling',
    'frangizzollatura':'Frangizzollatura',
    'fresatura':'Milling',
    'sarchiatura':'Weeding',
    'trinciatura':'Shredding',
    'sfalcio':'Mowing',
    'altro':'Other',
    'urea ammonio solfato':'Urea Ammonium Sulphate',
    'binario 25-15':'binary 25-15',
    'nitrato di magnesio':'Magnesium nitrate',
    'nitrato di calcio':'Calcium nitrate',
    'solfato di potassio':'Potassium sulfate',
    'solfato ammonico':'Ammonium sulfate',
    'scorie di Thomas':'Thomas slag',
    'polyhalite':'polyhalite',
    'perfosfato triplo':'Triple superphosphate',
    'perfosfato semplice':'Simple superphosphate',
    'nitrato di potassio 13 0 46':'potassium nitrate 13 0 46',
    'nitrato di ammonio 33.5 0 0':'Ammonium nitrate 33.5 0 0',
    'nitrato di ammonio 26 0 0':'Ammonium nitrate 26 0 0',
    'nitrato ammonico calcareo':'Calcareous ammonium nitrate',
    'fosforite':'Phosphorite',
    'fosfato monopotassico':'Monopotassium phosphate',
    'fosfato monoammonico':'Monoammonium phosphate',
    'fosfato bipotassico':'Dihypotassium phosphate',
    'fosfato biammonico':'Diammonium phosphate',
    'cloruro di potassio':'Potassium chloride',
    'cloruro d\'ammonio':'Ammonium chloride',
    'acido fosforico':'Phosphoric acid',
    'scegli un concime':'Choose a fertilizer',
    'compost':'Compost',
    'pollina':'Chicken manure',
    'liquame suino':'Pig slurry',
    'liquame bovino':'Bovine slurry',
    'letame equino':'Horse manure',
    'letame ovino':'Sheep manure',
    'letame bovino':'Bovine manure',
    'kilogrammi (kg)':'Kilograms (kg)',
    'grammi (g)':'Grams (g)',
    'parassiti':'Parasites',
    'molluschi':'Clams',
    'insetti':'Insects',
    'funghi':'Fungus',
    'infestanti':'Weeds',
    'litri (l)':'Liters (l)',
    'interrate in parte':'Partially buried',
    'interrate totalmente':'Completely buried',
    'asportate':'Removed',
    'non potato':'Not pruned',
    'graminacee':'Gramineae',
    'veccia':'Vetch',
    'favino':'Faba bean minor',
    'trifoglio incarnato':'Crimson clover',
    'trifoglio alessandrino':'Berseem clover',
    'trifoglio pratense':'Red clover',
    'erba medica':'Alfalfa',
    'nessuno':'None',
    'aggiungi l\'uso':'Add the use',
    'aggiungi un uso':'Add a use',
    'aggiungi una azienda':'Add a farm',
    'modello non gestito! contattare lo sviluppatore':'Unmanaged model! Contact the developer',
    'fine':'End',
    'modello non disponibile. si prega di connettersi alla rete':'Model not available. Please connect to the network',
    'non ci sono modelli disponibili':'There are no models available',
    'ultima':'last',
    'irrigazioni inserite':'Irrigations inserted',
    'portata':'Flow',
    'campo non irriguo':'Non-irrigated field',
    'impianto':'Irrigation system',
    'aggiungi un suolo':'Add soil',
    'attenzione! il campo non è associato a nessun suolo.':'Attention! The field is not associated with any soil.',
    'attenzione! la stagione è finita, stai vedendo un dato storico':'Attention! The season is over, you are seeing historical data',
    'attenzione! l\'anno selezionato esiste già':'Attention! The selected year already exists',
    'carica documenti':'Upload documents',
    'qual è il tuo ruolo?':'What\'s your role?',
    'oppure':'or',
    'accedi con google':'Login with Google',
    'vuoi davvero eliminare l\'azienda?':'Do you really want to eliminate the farm?',
    'errore nella cancellazione del campo':'Error in deleting the field',
    'non ha trovato il record':'Record not found',
    'errore nel salvataggio di':'Error saving',
    'errore di sincronizzazione':'Synchronization error',
    'ci sono stati degli errori durante la sincronizzazione':'There were some errors during the synchronization',
    'i dati sono stati salvati':'The data has been saved',
    'adesso':'Now',
    'aggiornato':'Updated',
    'attenzione, errore di connessione. questa funzione richiede di avere una connessione internet attiva':'Warning, connection error. This feature requires you to have an active internet connection',
    'potassio':'Potassium',
    'fosforo':'Phosphorus',
    'azoto':'Nitrogen',
    'potassio consigliato':'Recommended potassium',
    'potassio distribuito':'Distributed potassium',
    'potassio mancante':'Missing potassium',
    'azoto mancante':'Missing nitrogen',
    'azoto distribuito':'Distributed nitrogen',
    'azoto consigliato':'Recommended nitrogen',
    'fosforo mancante':'Missing phosphorus',
    'fosforo distribuito':'Distributed phosphorus',
    'fosforo consigliato':'Recommended phosphorus',
    'concimazioni inserite':'Fertilizers inserted',
    'nessuna concimazione inserita':'No fertilization inserted',
    'mortalità settimanale':'Weekly mortality',
    'mortalità':'Mortality',
    'data':'Date',
    'mortalità della mosca':'Fly mortality',
    'numero di olive punturate':'Number of punctured olives',
    'numero di olive sane':'Number of healthy olives',
    'olive punturate':'Punctured olives',
    'olive sane':'Healthy olives',
    'numero di catture':'Catch number',
    '00-gemme invernali':'00-winter dormancy',
    '01-gemme gonfie - apertura gemme':'01-start bud break',
    '09-gemme aperte':'09-bud break',
    "11-sviluppo fogliare":'11-leaf development',
    "33-accrescimento germoglio":'33-shoot development',
    "53-comparsa grappoli fiorali":'53-inflorescence buds are closed',
    "55-rigonfiamento bottoni fiorali":'55-inflorescence buds start to swell',
    "57-differenziazione corolla":'57-corolla differentiation',
    "61-inizio fioritura":'61-beginning of flowering',
    "65-fioritura 50%":'65-full flowering',
    "69-allegagione":'69-fruit set',
    "71-accresc. frutto post allegagione":'71-fruit growth after fruit set',
    "75-indurimento del nocciolo (0-50%)":'75-stone hardening 0-50%',
    "76-indurimento del nocciolo (>50%)":'76-stone hardening >50%',
    "77-nocciolo indurito":'77-stone hardening 100%',
    "79-accrescimento frutto olive verdi":'79-fruit growth green olives',
    "80-accrescimento frutto olive giallo verdi":'80-fruit growth yellow-green olives',
    "81-inizio invaiatura 5%":'81-veraison 5%',
    "83-invaiatura 50%":'83-veraison 50%',
    "85-invaiatura 100%":'85-veraison 100%',
    "91-maturazione":'91-maturation',
    "99-post raccolta":'99-post harvest',

    'fase media':'Middle phase',
    'infestazione completa':'Complete infestation',
    'infestazione semplice':'Simple infestation',
    'catture':'Catch',
    'fenologia dell\'olivo':'Phenology of the olive tree',
    'data di interramento':'Date of landfill',
    'gestione dei residui':'Residue management',
    'nome prodotto':'Product name',
    'quantità (kg/ha)':'Amount (kg/ha)',
    'fertilizzante':'Fertilizer',
    'potassio (k₂o) (kg/ha)':'Potassium (K₂O) (kg / ha)',
    'fosforo (p₂o₅) (kg/ha)':'Phosphorus (P₂O₅) (kg/ha)',
    'azoto ureico (kg/ha)':'Ureic nitrogen (kg / ha)',
    'azoto ammoniacale (kg/ha)':'Ammonia Nitrogen (kg/ha)',
    'azoto nitrico (kg/ha)':'Nitric nitrogen (kg/ha)',
    'unità/ha':'Unit/ha',
    'scegli un prodotto ed inserisci una quantità':'Choose a product and enter a quantity',
    'peso per ettaro':'Weight per hectare',
    'peso totale':'Total weight',
    'unità':'Unit',
    'nome commerciale':'Commercial name',
    'tipo prodotto':'Product type',
    'inserisci l\'irrigazione in mm':'Enter the irrigation in mm',
    'quantità (mm)':'Amount (mm)',
    'profondita (in cm)':'Depth (in cm)',
    'tipo di operazione':'Operation type',
    'scegli l\'operazione colturale':'Choose the cultivation operation',
    'trattamenti mosca dell\'olivo':'Fly treatments of the olive tree',
    'tipo di trattamento':'Treatment type',
    'quantità per ettaro':'Amount per hectare',
    'quantità totale':'Total amount',
    'unità di misura':'Unit of measure',
    'semina/trapianto':'Sowing/Transplanting',
    'lavorazione del suolo':'Soil tilling',
    'concimazione minerale':'Mineral fertilization',
    'concimazione organica':'Organic fertilization',
    'trattamenti':'Treatments',
    'potatura':'Pruning',
    'sovescio':'Green manure',
    'raccolta':'Harvest',
    'crea una nuova operazione colturale':'Create a new crop operation',
    'crea un nuovo monitoraggio':'Create a new monitoring',
    'modifica monitoraggio':'Edit monitoring',
    'aggiungi una nuova operazione':'Add a new operation',
    'elenco delle operazioni':'List of operations',
    'operazioni colturali':'Crop operations',
    'non ci sono dati':'There is no data',
    'aggiungi un nuovo rilievo':'Add a new observation',
    'elenco dei monitoraggi':'List of monitors',
    'sto scaricando i dati':'Downloading the data',
    'crea la nuova stagione':'Create new season',
    'nuovo anno di raccolta':'New year of harvest',
    'crea una nuova stagione':'Create a new season',
    'coltura nuova stagione':'New season crop',
    'copia gli usi colturali dall\'anno':'Copy crop uses from the year',
    'nessuna coltura':'No crops',
    'anno di raccolta':'Harvest year',
    'servono almeno tre punti':'At least three points are needed',
    'poligono disegnato correttamente':'Polygon drawn correctly',
    'superficie in metri quadri':'Area in square meters',
    'non è possibile cancellare il campo, ci sono dei dati associati.':'It is not possible to delete the field, there is associated data.',
    'non coltivato':'not cultivated',
    'hai appena creato l\'azienda. crea il tuo primo campo per poter continuare.':'You just created the company. Create your first field to be able to continue.',
    'nuova stagione':'New season',
    'nuova azienda':'New farm',
    'finisci':'Finish',
    'disegna il campo o inserisci la superficie':'Draw the field or insert the area',
    'poligono non disegnato':'Polygon not drawn',
    'uso del campo':'Field use',
    'spostati dove vuoi disegnare il primo punto e clicca su "inizia". se non vuoi disegnare il poligono clicca su "annulla".':'Move to where you want to draw the first point and click on "Start". If you don\'t want to draw the polygon click on "Cancel".',
    'inizia':'Start',
    'annulla':'Cancel',
    'disegna il campo':'Draw the field',
    'nome del campo':'Field name',
    'disegna il poligono':'Draw the polygon',
    'superficie in':'Area in',
    'coltura':'Crop',
    'uso':'Use',
    'manuale/di soccorso':'Manual/emergency',
    'irrigazione a goccia':'Drip irrigation',
    'impianto sprinkler':'Sprinkler system',
    'subirrigazione':'Sub Irrigation',
    'Irrigazione di superficie':'Surface irrigation',
    'altre tipologie di irrigazione':'Other types of irrigation',
    'irrigazione - portata in l/s':'Irrigation - Flow rate in l / s',
    'irrigazione':'Irrigation',
    'cancella l\'uso':'Delete the field use',
    'bassa':'Low',
    'alta':'High',
    'media':'Medium',
    'classe di vigoria':'Vigor class',
    'resa attesa':'Expected yield',
    'varietà':'Cultivar',
    'a':'in',
    'coltivato nella stagione':'cultivated in the',
    'stai modificando il campo':'You are editing the field',
    'aggiungi campo':'Add field',
    'salva i dati':'Save',
    'dipendente':'Employee',
    'proprietario':'Owner',
    'agronomo/consulente':'Agronomist',
    'vuoi davvero eliminare l\'uso del campo?':'Do you really want to delete the use of the field?',
    'vuoi davvero eliminare il collegamento?':'do you really want to delete the link?',
    'tu':'You',
    'in sospeso':'pending',
    "non è stato possibile creare l'invito. accertati di avere una connessione internet attiva.":'The invitation could not be created. Make sure you have an active internet connection.',
    'copia link':'Copy link',
    'invita col link':'Invite with link',
    'clicca al seguente indirizzo':'click on the following address',
    'come':'as',
    'se vuoi accedere all\'azienda':'If you want to access the farm',
    'benvenuto su':'Welcome to',
    'utenti associati all\'azienda':'Users associated with the farm',
    'non ci sono altri utenti collegati':'There are no other users connected',
    'manda un invito':'Send an invitation',
    'invita altre persone':'Invite other people',
    'funzioni':'Operation',
    'utente':'User',
    'ruolo':'Role',
    'scegli il ruolo':'Choose the role',
    'Inserisci il nome dell\'azienda':'Enter the farm name',
    'stazione meteo':'Weather station',
    'gestione stazione meteo':'Weather station management',
    'aggiorna':'Update',
    'inserimento azienda':'Farm insertion',
    'cerca l\'indirizzo dell\'azienda':'Search farm address',
    'posizione del centro aziendale':'Farm center location',
    'gestisci stazione meteo':'Manage weather station',
    'nome dell\'azienda':'Farm name',
    'azienda':'Farm',
    'vai':'Go',
    'aggiungi azienda':'Add farm',
    'ecco le aziende collegate a te':'Here are the farms connected to you',
    'sono un agronomo':'I am an agronomist',
    'sono una azienda':'I am a farm',
    'presente':'Present',
    'assente':'Absent',
    'geometria':'Geometry',
    'superficie':'Area',
    'campo':'Field',
    'mais':'Corn',
    'vite':'Grape',
    'olivo':'Olive',
    'lavori in corso. in questa pagina sarà possibile accedere ad una sintesi dei dati colturali.':'Works in progress. On this page it will be possible to access a summary of the crop data.',
    'mappa dei campi':'Fields map',
    'codice del campione':'Sample code',
    'data di campionamento':'Sampling date',
    'sostanza organica':'Organic substance',
    'azoto totale':'Total nitrogen',
    'fosforo':'Phosphorus',
    'potassio scambiabile':'Exchangeable potassium',
    'usa di default su tutti i campi':'Use by default on all fields',
    'salva':'Save',
    'usa suolo standard':'Use standard soil',
    'campi a cui il suolo è associato':'Fields with which the soil is associated',
    'crea un nuovo suolo':'Create a new soil',
    "vuoi davvero eliminare l'analisi del suolo":'do you really want to delete soil analysis?',
    'aggiungi suolo':'Add soil',
    'apri':'Open',
    'nome':'Name',
    'sabbia':'Sand',
    'argilla':'Clay',
    'elimina':'Delete',
    'attenzione. la somma di sabbia e argilla non può superare 100':'Caution. The sum of sand and clay cannot exceed 100',
    'suolo':'Soil',
    'usi del campo':'Uses of the field',
    'si prega di definire la cultivar del campo':'Please define the cultivar of the field',
    'modifica':'Edit',
    'nessuna fenologia inserita':'No phenology inserted',
    'si prega di definire la':'Please define the',
    'tipologia di irrigazione':'type of irrigation',
    '(se presente)':'(if any)',
    'giorni':'days',
    'giorno':'day',
    'evapotraspirazione della coltura':'Evapotranspiration of the culture',
    'pioggia totale':'Total rain',
    'giorni di stress idrico':'Days of water stress',
    'irrigazioni automatiche':'Automatic irrigations',
    'irrigazioni applicate dall’agricoltore':'Irrigations applied by the farmer',
    'data di riferimento':'Reference date',
    'nessuna irrigazione inserita':'No irrigation inserted',
    'aggiungi':'Add',
    'consulta il grafico':'Consult the chart',
    'campi':'Fields',
    'mappa':'Map',
    'aggiungi un campo':'Add field',
    'dati del campo':'Field data',
    'elenco dei campi':'List of fields',
    'gestisci le aziende':'Manage farms',
    "accessi all'azienda":'Farm access',
    'analisi dei suoli':'Soils analysis',
    'analisi del suolo':'Soil analysis',
    "modifica l'azienda":'Edit farm',
    'visualizza i campi':'Show your fields',
    'monitoraggio':'Monitoring',
    'operazioni colturali':'Crop operations',
    'ordinamento colturale':'Cropping system',
    'stagione':'season',
    'modello':'Model',
    'fase prevalente':'Prevalent phase',
    // 'gradi giorno': 'day degrees',
    'elimina il campo':'delete the field',
    'gradi giorno cumulati':'Day degrees cumulated',
    'fenologia dell\'olivo':'Olive phenology',
    "ciao":"Hi",
    "accedi":"Log in",
    "show_password":"Show password",
    "welcome_tec": "Welcome technician",
    "farm": "Farm",
    "action": "Action",
    "action": "Actions",
    "welcome": "Welcome",
    "access_anagrafica":"Access the registry",
    "anagrafe":"Registry",
    "su":"over",
    "mesi":"months",
    "parti":"deliveries",
    "search_in_table":"Search in table..",
    "advanced_registry":"Advanced registry",
    "chose_language": "Choose your language",
    "synchronize_data": "Synchronize data",
    "back_home_page": "Back to Home Page",
    "impostazioni": "Settings",
    "versione":"Version",
    "rams_choice":"Choice of rams",
    "ram_message":"From this function is possible to see the compatibility of your flock with the rams of other users and contact its owners.",
    "go_to_choice":"Go to the choice",
    "back":"Back",
    "esci":"Log out"

  }
};
