/*jshint esversion: 6 */
import global from '../../global.js';
import storage from '../../storage.js';
import * as anagrafica from './anagrafica.js';


export function storeImages(base_path){
  if (typeof base_path=='undefined') {
    base_path=global.base_call;
  }
  var settings=storage.get();
  // var images=settings.my_images;



  var all_images={};
  jQuery.each(settings.my_images,function(k,v){
    jQuery.each(v,function(i,d){
      all_images[i]=d;
      all_images[i].guid_survey=k;
    });
  });
  var images=all_images;

  var to_upload={};
  console.log("Ci sono "+Object.keys(images).length+" immagini<p/>");
  jQuery.each(images,function(k2,v2) {
    if(v2.saved==false || v2.updated==false){
      to_upload[k2]=v2;
    }
  });
  console.log("Ci sono "+Object.keys(to_upload).length+" immagini da caricare<p/>");

  console.log("TO UPLOAD");
  console.log(to_upload);

  var cont=0;
  jQuery.each(to_upload,function(k,v) {
    console.log(v);
    cont++;
    try{
      console.log("provo a caricare "+k);
      var call_type='insert';
      if(v.update){
        call_type='update';
      }

      var base64=v.base64;
      if (base64=='') {


        //debugger;
        global.getFileContentAsBase64(v.imageUrl,function(base64Image){
          var guid=k;
          console.log(v);
          base64=base64Image;
          // console.log(v.imageUrl);
          // console.log(base64);
          // console.log(k);
          // console.log(guid);
          var urladdr = base_path+"store_images/"+call_type+"/"+guid;
          jQuery.ajax({
            type: 'POST',
            // async:false,
            data:{
              guid_survey:v.guid_survey,
              note:v.commento,
              base64:base64,
              access_token:settings.user.access_token,
            },
            url: urladdr,
            dataType: "json",
            success: function(preverbale){
              console.log(" img "+guid+" "+preverbale.ok+"<p/>");
              updateImageStatus(guid, preverbale.ok);
            }
          });
        });
      }
      else {
        var guid=k;

        console.log("carico "+guid);
        var urladdr = base_path+"store_images/"+call_type+"/"+guid;
        jQuery.ajax({
          type: 'POST',
          // async:false,
          data:{
            guid_survey:v.guid_survey,
            note:v.commento,
            base64:base64,
            access_token:settings.user.access_token,
          },
          url: urladdr,
          dataType: "json",
          success: function(preverbale){
            if (!preverbale.ok) {
              alert(JSON.stringify(preverbale));
            }
            console.log(" img "+guid+" "+preverbale.ok+"<p/>");
            console.log(guid +' caricata '+preverbale.ok);
            updateImageStatus(guid, preverbale.ok);
          }
        });
      }
    }
    catch(e){
      console.log(e);
      console.log(" Errore con verbale "+k+" <p/>");
    }


  });
}

function updateImageStatus(guid, ok){
  var s=storage.get();

  jQuery.each(s.my_images,function(i,d) {
    jQuery.each(d,function(k,v){
      if(k==guid){
        console.log(v);
        v.saved=ok;
        v.updated=ok;
      }
    });
  });

  storage.save(s);
}

//Sincronizza gli animals
export function sync(id_farm, success, fail){

  var s=storage.get();
  var all_sync=true;
  var to_sync=[];


  var success_is_called=false;

  if (s['data_'+id_farm]) {
    if (s['data_'+id_farm].ok!==false) {
      jQuery.each(s['data_'+id_farm].animals.data, function(k,an){
        if(an.sync==false){
          all_sync=false;
          to_sync.push(an);
        }
      });
      jQuery.each(s['data_'+id_farm].recently_deleted.data, function(k,an){
        if(an.sync==false){
          all_sync=false;
          to_sync.push(an);
        }
      });
    }


    // console.log(s['data_'+id_farm]);
    success(s['data_'+id_farm]);
    success_is_called=true;
  }
  // else {
  //   s['data_'+id_farm]={};
  // }


  if(all_sync){
    console.log("tutto sincronizzato");
    getAnimalsAndOverwriteAll(id_farm, function(data){
      console.log(data);
      console.log("Dati sincronizzati");
      console.log(success_is_called);
      if(!success_is_called){
        success(data);
      }
    });
  }
  else{
    console.log("da sincronizzare");
    console.log(to_sync);
    // api di aggiornamento
    var urladdr = global.base_call+"sync_animals";
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        access_token:s.user.access_token,
        data:JSON.stringify(to_sync)
      },
      dataType: "json",
      success: function(data){
        var allOk=true;
        //TODO: occorre verificare che la sincronizzazione abbia avuto successo
        jQuery.each(data,function(k,v){
          allOk=allOk && v.ok;
        });

        jQuery.each(data,function(k,v){
          if (k.startsWith('update') || k.startsWith('insert')) {
            if (v.ok) {
              var s=storage.get();
              if(s["data_"+id_farm]){
                jQuery.each(s["data_"+id_farm].animals.data,function(i,d){
                  if (v.guid==d.guid) {
                    d.version=v.version;
                  }
                });
              }
              storage.save(s);
            }
          }
        });

        console.log("ERRORE nel success ");
        console.log(allOk);

        if(allOk){
          //se la sincronizzazione ha successo e non ci sono dati non sincronizzati li salva
          getAnimalsAndOverwriteAll(id_farm, function(data){
            console.log("Dati sincronizzati");
          });
        }
        else{
          if (typeof fail == 'function') {
            fail(data, to_sync);
          }
          else {
            alert("error: "+data.message);
          }
          console.log(data);
        }
      },
      error: function(e){

        console.log("ERRORE");

        global.app.dialog.close();
        console.log(e);
        if(typeof error=='function'){
          error(e);
        }
      },
      timeout: 30000
    });
  }
}

export function getArieti(id_farm, success,error){
  var s=storage.get();
  if(s.user) {

    var used_jstorage=false;

    if (s.arieti) {
      if(s.arieti[id_farm]){
        success(s.arieti[id_farm]);
        used_jstorage=true;
      }
    }
    else {
      s.arieti={};
    }

    if(!used_jstorage){
      global.app.dialog.preloader(global.loadingText);
    }

    var urladdr = global.base_call+"rams_score/"+id_farm;
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        access_token:s.user.access_token
      },
      dataType: "json",
      success: function(data){
        s.arieti[id_farm]=data;
        storage.save(s);

        if(!used_jstorage){
          global.app.dialog.close();
          success(data);
        }
      },
      error: function(e){

        global.app.dialog.close();
        console.log(e);
        // alert(e.statusText);
        if(error){
          error(e);
        }
      },
      timeout: 30000

    });
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

export function getAllFarms(success, error){
  var s=storage.get();
  if(s.user) {

    var used_jstorage=false;

    if(s.all_farms){
      success(s.all_farms);
      used_jstorage=true;
    }

    if(!used_jstorage){
      global.app.dialog.preloader(global.loadingText);
    }

    var urladdr = global.base_call+"get_all_farms";
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        access_token:s.user.access_token
      },
      dataType: "json",
      success: function(data){
        s.all_farms=data;
        storage.save(s);

        if(!used_jstorage){
          global.app.dialog.close();
          success(data);
        }
      },
      error: function(e){

        global.app.dialog.close();
        console.log(e);
        // alert(e.statusText);
        if(error){
          error(e);
        }
      },
      timeout: 30000

    });
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

//the function check if a possibleParent can be parent of an animal bor at a specific date
function canBeParent(possibleParent, date_birth,guid_animal){
// debugger
  //TODO prova se nullo deve essere oggi
  var ret=true;
  //esclude se stesso
  if(possibleParent.guid==guid_animal){
    ret=false;
  }
  else{
    try{
      if(date_birth){
        // debugger
        date_birth=new Date(date_birth);
      }
      else{
        date_birth=new Date();
      }

      var date_out_parent=new Date("3000-01-01");
      var date_birth_parent=new Date(possibleParent.date_birth);
      if(possibleParent.date_out){
        date_out_parent=new Date(possibleParent.date_out);
      }

      //Age of the possible parent
      var age_possible_parent=(date_birth-date_birth_parent)/(86400000*365);

      //the possible parent should be present whe the animal was born and it should have at least 1 year
      if(date_birth <= date_out_parent && age_possible_parent>1){
        ret = true;
      }
      else{
        ret=false;
      }
    }
    catch(e){
      ret= false;
    }
  }

  return ret;
}


export function getParents(id_farm, date_birth, guid_animal, success, error){
  var s=storage.get();
  if(s.user) {

    var parents=[];

    jQuery.each(s['data_'+id_farm].animals.data,function(k,a){
      if(canBeParent( a, date_birth, guid_animal) ){
        parents.push(a);
      }
    });

    jQuery.each(s['data_'+id_farm].recently_deleted.data,function(k,a){
      if(canBeParent( a, date_birth, guid_animal) ){
        parents.push(a);
      }
    });

    success(parents);
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

export function getAnimalsAndOverwriteAll(id_farm, success, error){
  // console.log(id_farm);

  var s=storage.get();
  if(s.user) {
    var used_jstorage=false;

    if(s["data_"+id_farm]){
      success(s["data_"+id_farm]);
      used_jstorage=true;
    }

    if(!used_jstorage){
      global.app.dialog.preloader(global.loadingText);
    }

    var urladdr = global.base_call+"get_animals";
    jQuery.ajax({
      type: "GET",
      url: urladdr,
      data:{
        access_token:s.user.access_token,
        id_farm:id_farm
      },
      dataType: "json",
      success: function(data){

        console.log(data);
        //TODO!!!! Attenzioe occorre verificare che ci siano degli animli non sincronizzati
        s["data_"+id_farm]=data;
        storage.save(s);

        if(!used_jstorage){
          global.app.dialog.close();
          success(data);
        }
      },
      error: function(e){

        global.app.dialog.close();
        console.log(e);
        // alert(e.statusText);
        if(error){
          error(e);
        }
      },
      timeout: 30000
    });

  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

export function getSingleAnimal(guid, id_farm, success){
  var s=storage.get();
  if(s.user) {

    var used_jstorage=false;

    if(s["data_"+id_farm]){
      var an=[];
      var animals=s["data_"+id_farm].animals.data;
      an=animals.filter(function(f){
        return f.guid==guid;
      });

      if (an.length==0) {
        var recently_deleted=s["data_"+id_farm].recently_deleted.data;
        an=recently_deleted.filter(function(f){
          return f.guid==guid;
        });
      }

      success({"ok": true, "animals": {"data":an}});
      used_jstorage=true;
    }
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }
}

export function insertAnimal(animal, success){

  console.log(animal);

  var s=storage.get();

  var used_jstorage=false;
  var id_farm=animal.id_farm;

  if(!animal.json){
    animal.json={"events":[]};
  }

  animal.version=0;
  animal.sync=false;
  if(s["data_"+id_farm]){
    s["data_"+id_farm].animals.data.push(animal);
    storage.save(s);

    success(s["data_"+id_farm], animal);
    used_jstorage=true;
  }
}


//prende un animale dallo storage
export function get_animal_from_guid(guid, id_farm,from_deleted){
  var s=storage.get();
  var animal=null;
  if(s["data_"+id_farm]){
    jQuery.each(s["data_"+id_farm].animals.data,function(k,v){
      if (v.guid==guid) {
        animal=v;
        animal.json=anagrafica.get_json_fixed(animal.json);
      }
    });
    if (animal==null) {
      jQuery.each(s["data_"+id_farm].recently_deleted.data,function(k,v){
        if (v.guid==guid) {
          animal=v;
          animal.json=anagrafica.get_json_fixed(animal.json);
        }
      });
    }
  }

  return animal;
}

export function save_animal(animal){
  console.log(animal);
  var s=storage.get();
  jQuery.each(s['data_'+animal.id_farm].data,function(k,v){
    if (v.guid==animal.guid) {
      v=animal;
    }
  });

  storage.save(s);
}

export function updateAnimal(animal, success){
  var s=storage.get();

  console.log(animal);

  var used_jstorage=false;
  var id_farm=animal.id_farm;

  animal.sync=false;

  //Prendo l'animale nella storia
  var animal_old=get_animal_from_guid(animal.guid, animal.id_farm,true);

  if(animal_old!==null){

    //faccio il merge
    jQuery.each(animal,function(i,d){
      animal_old[i]=d;
    });
    storage.save(s);
    success(s["data_"+id_farm]);
    used_jstorage=true;
  }
}


//Save last_event_type
export function saveLastEventType(event_type){
  var s=storage.get();
  s.event_type=event_type;
  storage.save(s);
}

//Get last_event_type
export function getLastEventType(){
  var s=storage.get();
  return s.event_type;
}
