/*jshint esversion: 6 */
import global from '../../global.js';

export function dbmng2(){
  console.log("ccc");
  var html='';
  html+="<div id='dbmng'>AAA</div>";
  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var aForm = { table_name: 'test',
    primary_key: Array('id'),
    fields: {
      id: {label: 'ID', type: 'int', key: 0, nullable: false},
      name: {label: 'Name', type: 'varchar', 'default': 'Paperino', 'validator': {'regexp': '/^[A-Z][0-9]{3}$/', 'message':'Usare 1 lettere e 3 numeri (es. A123)'}},
      passwd: {label: 'Password', type: 'varchar', widget:'password', classes: 'aaa bbb ccc'},
      month: {label: 'Mounth', widget:'select', type: 'int', voc_val: {2:'February', 1:'January'}},
      check: {label: 'Check', type: 'int', widget:'checkbox'},
      multiple: {label: 'Multiple', type: 'int', widget:'select_nm', voc_val: {1:'January', 2:'February', 3:'March', 4:'April'}},
      multiplec: {label: 'Multiple checkbox', type: 'int', widget:'select_nm', voc_val: {1:'January', 2:'February', 3:'March', 4:'April'}, out_type:'checkbox'},
      data: {label: 'Data', type: 'date', widget:'date', date_format_view:'MM d, yy'}
    }
  };

  //TODO da mofificare
  var theme_f7_class = Dbmng.AbstractTheme.extend({
    getLabel: function(aField) {
      var el = this._super(aField);
      jQuery(el).css('font-weight','bold');
      return el;
    },
    assignAttributes: function(el, aField) {
      console.log(el);
      console.log(aField.widget);

      this._super(el, aField);
      this.addClass(el, 'form-control');
    },
    alertMessage: function(text) {
      var el = this._super(text);
      this.addClass(el, 'alert alert-block alert-danger');
      return el;
    },
    getTable: function(opt) {
      var div = this._super(opt);
      this.addClass(div.firstChild, 'data-table card');
      return div;
    },
    getButton: function(text, opt) {
      var el = this._super(text, opt);
      this.addClass(el, 'button button-raised');
      return el;
    },
    createFileUploadField: function(elv, label, opt){
      var el = this._super(elv, label, opt);
      var btn=jQuery(el).find('.fileinput-button');
      btn.css('width','');
      btn.addClass('col-50');

      var prg=jQuery(el).find('.progress');
      prg.css('width','');
      prg[0].style.cssText="";
      prg.wrap('<div class="col-50" style="padding-top: 7px;"></div>');
      prg.find('.progress-bar')[0].style.cssText="";

      return el;
    },
    getDeleteButton: function(label,btn_icon){

      var icn = document.createElement('i');
      this.addClass(icn,btn_icon);
      this.addTitle(icn, label);
      return icn;
    }
  });

  //Dalla classe si crea l'oggetto
  var theme_f7 = new Dbmng.Framework7Theme();

  var form=new Dbmng.Form({aForm:aForm, aParam:{}, theme:theme_f7});
  jQuery('#dbmng').append(form.createForm({}));
}
