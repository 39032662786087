/*jshint esversion: 6 */
import storage from '../../storage.js';
import global from '../../global.js';

export default function sheepTools(){
  var codiceBDN=global.codiceBDN;

  var s=storage.get();
  if (s.codiceBDN) {
    codiceBDN=s.codiceBDN;
  }

  var html='';
  html+='<li class="item-content item-input">';
    html+='<div class="item-inner">';
      html+='<div class="item-title item-label">Codice BDN</div>';
      html+='<div class="item-input-wrap">';
        html+='<input id="codiceBDN" type="text" placeholder="Codice BDN" value="'+codiceBDN+'">';
      html+='</div>';
    html+='</div>';
  html+='</li>';

  jQuery('#appSettings').append(html);

  jQuery("#appSettings").change(function() {
    jQuery.each(jQuery("#appSettings input"),function(k,v){
      s[jQuery(v).attr('id')]=jQuery(v).val();
    });
    storage.save(s);
  });
}
