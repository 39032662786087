/*jshint esversion: 6 */
import global from '../../global.js';
import storage from '../../storage.js';

//funzione principale di analisi della connessione
export function report(){
  var s=storage.get();

  var html='';
  // html+='Funzione sperimentale. Non è ancora implementata. Da qui sarà possiible inviare via email una serie di report in excel sulla singola azienda.<p/>';

  // html+="Email: <input value='diego.guidotti@gmail.com'></input>";

  html+=`
  <div class="block block-strong inset">
    <p>Da qui è possible inviare via e-mail il report sulla Banca dati animali dell'azienda in excel.</p>
  </div>

  <div class="block-title">Email</div>
  <div class="list inset">
    <ul>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-input-wrap">
            <input id="email" type="email" placeholder="Your e-mail" value="${s.user.email}" />
            <span class="input-clear-button"></span>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <a id="send_mail" style="margin:15px;" class="button button-round button-fill">Invia e-mail</a>

  <div class="block block-strong inset">
  <p id="email_message"></p>
  </div>
  `;

  // html+="<ul>";
  // html+="<li><input name='report_type' type='radio' value='bdn'></input>Banca dati animali - attuali</li>";
  // html+="<li><input name='report_type' type='radio' value='bdn_hist'></input>Banca dati animali - storica</li>";
  // html+="<li><input name='report_type' type='radio' value='eco'></input>Ecografie (elenco di tutte le ecografia fatte su tutti i capi)</li>";
  // html+="<li><input name='report_type' type='radio' value='parti'></input>Parto (elenco di tutte i parti fatte su tutti i capi)</li>";
  // html+="</ul>";
  html+='<div id="report"></div>';

  var content={'title': 'Report', 'content': html, exclude_card:true};
  global.theme.render(content);

  jQuery('#send_mail').click(function(){


    jQuery('#send_mail').hide();

    jQuery("#email_message").html(`Attendi, stiamo per mandare la mail...`);

    sendMail(jQuery('#email').val());
  });

}

function sendMail(email){
  var s=storage.get();

  var urladdr = global.base_call+"send_mail";
  jQuery.ajax({
    type: "GET",
    url: urladdr,
    data:{
      access_token:s.user.access_token,
      id_farm:s.id_farm,
      email:email
    },
    dataType: "json",
    success: function(data){
      if (data.ok) {
        
        jQuery("#email_message").html(`La mail è stata inviata correttamente`);
        jQuery('#send_mail').show();
      }
      else {
        jQuery("#email_message").html(`C'è stato un errroe: ${data.message}`);
        jQuery('#send_mail').show();
      }
    },
    error: function(err){
      console.log(err);
    }
  });
}


//La funzione scopre i device. Viene lanciata solo se il device non è connesso
function device_discover(){
  discoverUnpaired(function(devices) {
    global.app.dialog.close();

    console.log("101 discovery");
    console.log(devices);
    var html='';
    html+='<div class="block-title">Dispositivi non accoppiati</div>';
    html+='<div class="unpaired list">';
      html+='<ul>';
        var unpaired=[];
        devices.forEach(function(device) {
          console.log(device.id);
          unpaired.push(device.id);
          html+='<li class="bluetooth_device" mac_address="'+device.id+'">';
            html+='<div class="item-content">';
              html+='<div class="item-inner">';
                var mac_address=device.id;
                var label=device.id;
                var className="";
                if (device.name) {
                  label=device.name;
                  if(label.startsWith('RS420')){
                    className="lettore_bolo";
                    label+=" (Lettore Chip)";
                  }
                }
                html+='<div class="item-title '+className+'" id="'+mac_address+'">'+label+'</div>';
                // html+='<div class="item-after" id="'+mac_address+'_connected"></div>';
              html+='</div>';
            html+='</div>';
          html+='</li>';
        });
      html+='</ul>';
    html+='</div>';

    jQuery('#bluetooth').html(html);

    // Quando clicco su un device nella lista, mi connetto al device
    jQuery(".bluetooth_device").click(function() {
      global.app.dialog.progress("Connessione in corso..");

      console.log("102 connessione");
      var macAddress=jQuery(this).attr('mac_address');

      connectDevice(macAddress);

    });


  },
  function(error){
    global.app.dialog.close();
    console.log(error);
  });
}


//la funzione
function device_connected(){
  console.log("10. connected");

  global.app.ptr.destroy('.ptr-content');
  global.app.dialog.close();

  pageBluetoothConnected();

}

function checkIfIsConnected(connected, unconnected){
  console.log("2. check bluetooth");
  // Controllo se il dispositivo è connesso ad un altro dispositivo bluetooth
  if (global.is_cordova()) {
    bluetoothSerial.isConnected(

      function() {
        // jQuery('#paired').show();
        console.log("3. Bluetooth is connected");
        connected();
      },
      function() {
        // jQuery('#paired').hide();
        console.log("3. Bluetooth is *not* connected");
        unconnected();
      }
    );
  }
  else {
    connected();
  }
}


//abilito il bluethoot e se è abilitato eseguo una funzione
function enableBluetooth(success_function){
  console.log("attivo bluetooth");
  if (global.is_cordova()) {
    if(bluetoothSerial){
      // Eseguo la procedura per attivare il bluetooth.
      bluetoothSerial.enable(function(ok){
        console.log(ok);
        success_function();
      }, function(error){
        console.log(error);
        alert(error);
      });
    }
    else{
      alert("Attenzione errore nell'app -  manca il modulo BT");
    }
  }
  else {
    success_function();
  }
}


function pageBluetoothConnected(){

  var html='';
  html+='<div class="block-title">L\'app è connessa in maniera corretta con il bluethoot.</div>';
  html+='<div class="block block-strong">Adesso puoi iniziare ad usare il dispositivo per leggere i chip.';
  html+='</div>';
  html+="<button class='button button-fill color-red' id='bt_disconnect'>Disconnettiti</button>";

  jQuery('#bluetooth').html(html);

  //attiva la lettura
  readBluetooth();

  // Quando clicco su un device già connesso, effettuo la disconnessione
  jQuery("#bt_disconnect").click(function() {
    global.app.dialog.progress("Disconnessione in corso..");

    disconnectDevice();
  });

}

function disconnectDevice(){
  console.log("disconnect bluetooth");

  if (global.is_cordova()) {
    bluetoothSerial.disconnect(
      function(ok){
        global.app.dialog.close();
        console.log(ok);
        location.reload();
      },
      function(error){
        global.app.dialog.close();
        console.log(error);
      }
    );
  }
  else {
    global.app.dialog.close();
  }
}

function connectDevice(macAddress){
  console.log("13. connect bluetooth");

  if (global.is_cordova()) {
    // Connessione al device
    bluetoothSerial.connect(macAddress,
      function(ok){
        console.log("14. connected bluetooth");
        global.app.dialog.close();
        console.log(ok);

        pageBluetoothConnected();
      },
      function(error){
        global.app.dialog.close();
        console.log(error);
      }
    );
  }
  else {
    global.app.dialog.close();
  }
}

function readBluetooth(){
  console.log("Leggo bluetooth");

  var html='';
  html+='<div style="display:none;" class="tagTable2 data-table data-table-init card">';
    html+='<div class="card-content">';
      html+='<table id="animal_table">';
        html+='<thead>';
          html+='<tr>';
            html+='<th class="label-cell">Codice azienda</th>';
            html+='<th class="label-cell">Codice BDN!</th>';
            // html+='<th class="label-cell">Data/ora lettura</th>';
          html+='</tr>';
        html+='</thead>';
        html+='<tbody id="animals_tbody2">';
        html+='</tbody>';
      html+='</table>';
    html+='</div>';
  html+='</div>';
  jQuery('#readonly').html(html);



//Funzione che unisce due binari (ArrayBuffer)
var _appendBuffer = function(buffer1, buffer2) {
  var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

//Funzione che converte un binario (ArrayBuffer) in base64
function _arrayBufferToBase64( buffer ) {
 var binary = '';
 var bytes = new Uint8Array( buffer );
 var len = bytes.byteLength;
 for (var i = 0; i < len; i++) {
   binary += String.fromCharCode( bytes[ i ] );
 }
 return window.btoa( binary );
}


//Crea l'array byte vuoto
var byte=new Uint8Array();
var last_code="";

subscribe('\n',function (data) {

  //Accoda i dati binari ad un binari vuoto
  byte=_appendBuffer(byte, data);

  try{
    var base64 = _arrayBufferToBase64(byte);
    var base64_single = _arrayBufferToBase64(data);
    try{
        //decodifica il Binario in una stringa
        last_code=window.atob(base64);

        //Se vede un VQ== svuota l'array, altrimenti a volte salta tutto (si accavallano?)
        if(base64_single=='VQ=='){
        	last_code="";
          byte=new Uint8Array();
        }

        //se la stringa raggiunge una lunghezza limite la processa
        if(last_code.length>=30){
      		save_code(last_code);
        }
    }
    catch(e2){
      //se fallisce la conversione del binario in stringa prova ad usare l'ultimo last_code
      save_code(last_code);
      alert("E2"+last_code);
    }
  }
  catch(e){
    alert("E1"+e);
  }

});






// subscribe_old('\n',function (data) {
//
//     alert("0"+data);
//     var dato_grezzo="";
//     dato_grezzo=data;
//     try{
//       dato_grezzo=JSON.stringify(data);
//       alert("1|"+dato_grezzo);
//       dato_grezzo=_arrayBufferToBase64(data);
//       alert("2|"+dato_grezzo);
//     }
//     catch(e){
//       alert("E1"+e);
//     }
//
//     alert("b");
//
//     var s=storage.get();
//     var urladdr = global.base_call+"get_animals";
//     jQuery.ajax({
//       type: "GET",
//       url: urladdr,
//       data:{
//         access_token:s.user.access_token,
//         code_bdn:dato_grezzo,
//         id_farm:"my",
//         bt: true
//       },
//       dataType: "json",
//       success: function(data){
//         console.log(JSON.stringify(data));
//         alert(JSON.stringify(data));
//         console.log("open the animal");
//       },
//       error: function(err){
//           alert("E3"+JSON.stringify(err));
//       }
//     });
//   }, function(er){
//     var error=er;
//     if(typeof er=='object'){
//       error=JSON.stringify(er);
//     }
//     console.log(error);
//     alert(error);
//   });
//
//
//   //TODO Aggiungere un controllo (anche un try/catch) il valore grezzo di data deve essere comunque visualizzabile anche prima di fare tuutee le chiamate
//   jQuery('#animals_tbody2').append('<tr><td>'+codice_aziendale+'</td><td>'+dato_grezzo+'(grezzo)</td></tr>');
//
//   jQuery('.tagTable2').show();
//
//   alert(dato_grezzo);


}


function save_code(dato_grezzo){

  var dato_ok=dato_grezzo;


  var asterix=(dato_grezzo.indexOf("*"));
  if(asterix>-1){
    dato_ok="IT"+(dato_grezzo.substr(asterix-12,12) );
  }
  else if(dato_grezzo.substr(8,3)=='999'){
    dato_ok="999"+(dato_grezzo.substr(11,12) );
  }
  else if(dato_grezzo.substr(8,3)=='380'){
    dato_ok="IT"+(dato_grezzo.substr(11,12) );
  }


  var s=storage.get();
  var id_farm=null;

  id_farm=s.id_farm;

  if ( (typeof id_farm=='undefined' || id_farm==null) && s.res && s.res.farm) {
    id_farm=s.res.farm.data[0].id_farm;
  }
  var codice_aziendale=dato_ok.substr(dato_ok.length-8);

  var animal_exist=false;
  var guid=null;
  jQuery.each(s['data_'+id_farm].animals.data,function(k,v){
    if (v.code_bdn==dato_ok) {
      animal_exist=true;
      guid=v.guid;
    }
  });

  if (animal_exist) {
    location.hash="#farm/"+id_farm+"/anagrafe/advanced/view/"+guid;
    console.log("open the animal");
  }
  else {
    location.hash="#farm/"+id_farm+"/anagrafe/advanced/new/"+codice_aziendale+"/"+dato_ok;
    console.log("create the animal");
  }


  //get animal la lasciamo perche serve per loggare le letture ma a lungo termine va eliminata
  var urladdr = global.base_call+"get_animals";
  jQuery.ajax({
    type: "GET",
    url: urladdr,
    data:{
      access_token:s.user.access_token,
      code_bdn:dato_ok,
      id_farm:id_farm,
      bt: true
    },
    dataType: "json",
    success: function(data){
      try{
        console.log(JSON.stringify(data));
        console.log("open the animal");
      }
      catch(e){
        alert("E7"+e);
      }

    },
    error: function(err){
        alert("E3"+JSON.stringify(err));
    }
  });
}
