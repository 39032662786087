/*jshint esversion: 6 */
import t from '../../translate.js';

export function scegliAziendaHomeCard(){
  var html='';
  // html +="Da questa pagina è possibile da parte del tecnico scegliere un'azienda per poter accedere ai dati ed alle funzioni aziendali.";
  html+='<div class="data-table card">';
    html+='<table>';
      html+='<thead>';
        html+='<tr>';
          html+='<th>'+t('farm')+'</th>';
          html+='<th>'+t('action')+'</th>';
        html+='</tr>';
      html+='</thead>';
      html+='<tbody id="farm_list">';
      html+='</tbody>';
    html+='</table>';
  html+='</div>';

  return html;
}
