/*jshint esversion: 6 */

import '../style.css';

import global from '../../../global.js';
import storage from '../../../storage.js';
import gps from '../../../core/gps.js';
import marketing from '../../../core/marketing.js';
import notification from '../../../core/notification.js';

import t from '../../../translate.js';
import * as report from '../report.js';

import * as sheep from '../sheep.js';
import * as anagrafica from '../anagrafica.js';
import * as arieti from '../arieti.js';
import sheepTools from '../tools.js';
import * as bluetooth from '../bluetooth_new.js';
import * as db from '../db.js';


import dbmng from '../../../ext/dbmng.js';
import * as dbmngf7 from '../dbmng2-f7.js';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// global.base_call='http://kleanpc.aedit.it/sheep/api_mod_sheep_anag/';
global.nomeApp='SheepAllChain';
global.base_path='https://sheepallchain.aedit.it/';
global.base_call='https://sheepallchain.aedit.it/api_mod_sheep_anag/';
global.base_call_marketing='https://sheepallchain.aedit.it/api_mod_sheep_marketing/';
global.download_app_path='https://sheepallchain.aedit.it/app/SheepAllChain.apk';
global.language='it';
// global.settingsName='sheepallchain_settings';

// Your web app's Firebase configuration
global.firebaseConfig = {
  apiKey: "AIzaSyADkdMyr0e0ncualJcvhaR9qENz_opqITM",
  authDomain: "sheepallchain-ecaa1.firebaseapp.com",
  databaseURL: "https://sheepallchain-ecaa1.firebaseio.com",
  projectId: "sheepallchain-ecaa1",
  storageBucket: "sheepallchain-ecaa1.appspot.com",
  messagingSenderId: "996914288953",
  appId: "1:996914288953:web:19709447b60586acd270e8"
};


if (notification) {
  global.notification=notification;
}

notification.default_notify();

export function tools(){
  sheepTools();
}

//Hook obbligatorio per definire la home progetto
export function home_page(){
  var s=storage.get();

  global.codiceBDN='IT0054';

  try{
    global.id_farm=s.id_farm;

    if ((typeof global.id_farm=='undefined' || global.id_farm==null) && s.res.farm.rowCount==1) {
      global.id_farm=s.res.farm.data[0].id_farm;
    }
  }
  catch(error){
    if (!s.user) {
      location.hash='login';
    }
    console.log(error);
  }


  if(s.user){
    if(s.user.roles.indexOf("userplus_admin")>-1){
      if (s.id_farm) {
        homeFarm(s.id_farm);
      }
      else {
        homeAdvisor();
      }    }
    else{
      if (s.id_farm) {
        homeFarm(s.id_farm);
      }
      else {
        homeFarm(global.id_farm);
      }
    }
  }
  else{
    homeNoUser();
  }
}



//Pagina per l'utente non registrato
//per adesso reindirizziamo sul login
function homeNoUser(){
  location.hash='#login';
}

//Pagina che permette all'utente di scegliere la farm che vuole vedere
function homeAdvisor(){

  // checkVersion();
  var html='';
  html+="<div class='block-title central'>"+t('welcome_tec')+" <br><i id='nome_azienda'></i></div>";

  html+=sheep.scegliAziendaHomeCard();

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  arieti.getFarms();

  var smartSelect = global.app.smartSelect.create({
    el:'#farm_list_select',
    openIn:'popup',
    popupCloseLinkText:'Avanti',
    searchbar:true,
    searchbarPlaceholder:'Cerca',
    searchbarDisableText:'Annulla',
    appendSearchbarNotFound:'Nessun risultato',
    on: {
      closed: function () {
        // location.hash="anagrafe/advanced/farm/"+jQuery('#farm_list').val();
        location.hash="farm/"+jQuery('#farm_list').val();
      }
    }
  });
  console.log(smartSelect);

  jQuery('#footer').parent().hide();


  //TODO veriificare se per l'advisor non chiamiamo getregge ma una API dedicata
  //arieti.getGregge('my');

  //1. Chiamare un api get_my_farm che popola tutto
//2 caricae la select -> fai una tabella con le aziende
// else {
//   html='';
//   jQuery.each(data.all_farms.data,function(k,v){
//     html+='<option value="'+v.id_farm+'">'+v.farm_name+'</option>';
//   });
//
//   jQuery('#farm_list').html(html);
//
//   jQuery('.farm_select').show();
// }
}

//Pagina che mostra tutti i dati di una farm
function homeFarm(id_farm){
  var s=storage.get();

  // debugger
  var farm_reference='my';
  if (id_farm) {
    farm_reference=id_farm;

    s.id_farm=id_farm;
    storage.save(s);

    location.hash='';
  }


  var html='';
  html+="<div class='block-title central'>"+t("welcome")+" <br><i id='nome_azienda'></i></div>";

  html+=anagrafica.anagraficaHomeCard();
  html+=anagrafica.partiHomeCard();
  html+=arieti.arietiHomeCard();

  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);


  jQuery('#footer').parent().hide();

  jQuery("#arieti").click(function() {
    location.hash="/farm/"+farm_reference+"/arieti";
  });
  jQuery(".icons_animals").click(function() {
    location.hash="/farm/"+farm_reference+"/anagrafe/advanced";
  });
  jQuery(".icons_parti").click(function() {
    location.hash="/farm/"+farm_reference+"/anagrafe/parti";
  });

  arieti.getGregge(id_farm);
}

function cleanPage(){
  var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">'+t('back')+'</span></a>';
  jQuery('.left').html(html);

  jQuery('#download_app').hide();

  jQuery('#footer').parent().show();
  jQuery('#footer').html('');
}

//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  jQuery('#footer').html('');

  router.on({
    // 'dbmng2': function () {
    //   cleanPage();
    //
    //   dbmngf7.dbmng2();
    // },
    'farm/:id_farm': function (param) {
      cleanPage();

      homeFarm(param.id_farm);
    },
    'report':function(){
      jQuery('#download_app').hide();

      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      report.report();
    },
    'farm/:id_farm/arieti': function (param) {
      cleanPage();

      arieti.allArieti(param.id_farm);
    },
    'farm/:id_farm/arieti_richieste': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);

      arieti.arietiRichieste(param.id_farm);
    },
    'farm/:id_farm/arieti/:id_ariete': function (param) {
      cleanPage();

      arieti.ariete(param.id_ariete,param.id_farm);
    },
    'bluetooth':function(){
      jQuery('#download_app').hide();

      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      bluetooth.bluetooth();
    },
    'manage_farm': function (){
      homeAdvisor();
    },
    'dati_aziendali':function(){
      jQuery('#download_app').hide();

      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      marketing.datiAziendali();
    },
    // 'anagrafe': function () {
    //   cleanPage();
    //
    //   anagrafica.anagrafe();
    // },
    // 'anagrafe/storic': function () {
    //   cleanPage();
    //
    //   anagrafica.anagrafe_storic();
    // },
    // 'anagrafe/advanced': function () {
    //   cleanPage();
    //
    //   anagrafica.anagrafe_advanced();
    // },
    // 'anagrafe/advanced/farm/:id_farm': function (param) {
    //   cleanPage();
    //
    //   anagrafica.anagrafe_advanced(param.id_farm);
    // },
    'farm/:id_farm/anagrafe/parti/': function (param) {
      cleanPage();

      anagrafica.parti_advanced(param.id_farm);
    },
    'farm/:id_farm/anagrafe/advanced/': function (param) {
      cleanPage();

      anagrafica.anagrafe_advanced(param.id_farm);
    },
    'farm/:id_farm/anagrafe/advanced/new': function (param) {
      cleanPage();

      anagrafica.anagrafe_new(param.id_farm);
    },
    'farm/:id_farm/anagrafe/advanced/:guid': function (param) {
      cleanPage();

      anagrafica.anagrafe_edit(param.guid, param.id_farm);
    },
    'farm/:id_farm/anagrafe/advanced/view/:guid': function (param) {
      cleanPage();

      anagrafica.anagrafe_view(param.guid, param.id_farm);
    },
    'farm/:id_farm/anagrafe/advanced/new/:cod_animale/:cod_bdn': function (param) {
      cleanPage();

      anagrafica.anagrafe_new(param.id_farm,param.cod_animale,param.cod_bdn);
    },
    // 'anagrafe/advanced/:guid/farm/:id_farm': function (param) {
    //   cleanPage();
    //
    //   anagrafica.anagrafe_edit(param.guid,param.id_farm);
    // },
    'farm/:id_farm/anagrafe/advanced/remove/:guid/:type_out': function (param) {
      cleanPage();

      anagrafica.anagrafe_remove(param.guid,param.type_out, param.id_farm);
    },
    'upload_bdn': function (param) {
      cleanPage();

     report.upload_bdn();
    },
  });
  return router;
}

export function get_theme_param(theme_param){
  var s=storage.get();

  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Dati aziendali', 'icon_f7':'doc_richtext', 'href':'#dati_aziendali'},
    {'label': 'Bluetooth', 'icon_f7':'antenna_radiowaves_left_right', 'href':'#bluetooth'},
    {'label': 'Report', 'icon_f7':'book_circle', 'href':'#report'},
    {'label': 'Carica BDN', 'icon_f7':'cloud_upload', 'href':'#upload_bdn'}

  ];

  if (s.user) {
    if(s.user.roles.indexOf("userplus_admin")>-1 || s.user.roles.indexOf("org_admin")>-1){
      sidebars.push({'label': 'Gestisci azienda', 'icon_f7':'gauge', 'href':'#manage_farm'});
    }
  }

  theme_param.sidebars=sidebars;
  theme_param.title='SheepAllChain';
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}
